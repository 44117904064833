import {
  CalendarEntryId,
  NotificationBundle,
  NotificationType,
  AccountId,
  TeamId,
  SoccerGame,
  PlayerId,
  LowPriorityNotificationDetail,
  LowPriorityNotificationDetailType,
  PushNotificationSettingToRespect,
  PrettyPlayer,
  Team,
  PrettyPlayerBundle,
  PlayerBundle__AccountType,
  CalendarEntryGameScrimmage
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { generatePushID } from "../../internal-utils/firebaseId";
import { processNotificationBundles } from "./notification.plumbing";
import shortid from "shortid";
import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { ObjectKeys } from "../../utils/object-keys";
import moment from "moment";
import _ from "lodash";
import { fetchPrettyPlayerList } from "../../internal-utils/player-utils";
import { playerBundle__server__getPrettyPlayerBundlesFromPlayerIds } from "../playerBundle";
import { timeHasPassedSinceScheduledGameStart } from "../../compute";
import { translate } from "@ollie-sports/i18n";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { fetchAccountPrivatesCached } from "../../utils/date-helpers";

export async function notification__server__triggerForVotingClosed(p: {
  soccerGame: SoccerGame;
  numVotesMVP: Record<PlayerId, number>;
  numVotesOffense: Record<PlayerId, number>;
  numVotesDefense: Record<PlayerId, number>;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [team, calendarEntry] = await Promise.all([
    h.Team.getDoc(p.soccerGame.teamId),
    h.CalendarEntry.getDoc(p.soccerGame.calendarEntryId)
  ]);

  if (!team) {
    return { success: false, errorMessage: "Error fetching team:" + p.soccerGame.teamId };
  }

  if (!calendarEntry) {
    return { success: false, errorMessage: "Error fetching calendarEntry:" + p.soccerGame.calendarEntryId };
  }

  // Don't send notification if the game was scheduled in the past
  // Most likely watching film afterwards
  if (timeHasPassedSinceScheduledGameStart(calendarEntry as CalendarEntryGameScrimmage, 6)) {
    return;
  }

  const allPlayerIdsReceivingVotes = _.uniq([
    ...ObjectKeys(p.numVotesDefense),
    ...ObjectKeys(p.numVotesOffense),
    ...ObjectKeys(p.numVotesMVP)
  ]);

  // const allPlayersReceivingVotes = await h.Player.get(allPlayerIdsReceivingVotes);
  // const allPrettyPlayersReceivingVotes = await fetchPrettyPlayerList(allPlayersReceivingVotes);
  const allPrettyPlayerBundlesReceivingVotes = await playerBundle__server__getPrettyPlayerBundlesFromPlayerIds({
    playerIds: allPlayerIdsReceivingVotes,
    teamId: p.soccerGame.teamId
  });

  let accountIds: AccountId[] = [];
  if (!p.soccerGame.squad) {
    ObjectKeys(team.accounts).forEach(accId => {
      accountIds.push(accId);
    });
  } else {
    accountIds = await fetchAccountIdsOnSquad({
      team,
      squad: p.soccerGame.squad,
      squadSubset: "all"
    });
  }

  const accountPrivates = await fetchAccountPrivatesCached({ accountIds });
  const now = Date.now();
  const triggerEventId = "voting-closed-" + p.soccerGame.id;
  const notificationBundles: NotificationBundle[] = [];
  accountIds
    .map(accountId => ({ accountId, id: generatePushID() }))
    .map(a => {
      const locale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
      const { body, title } = generateBodyAndTitleForVotingClosedNotification({
        accountId: a.accountId,
        numVotesDefense: p.numVotesDefense,
        numVotesMVP: p.numVotesMVP,
        numVotesOffense: p.numVotesOffense,
        playerBundles: allPrettyPlayerBundlesReceivingVotes,
        team,
        locale
      });
      const lp: LowPriorityNotificationDetail = {
        body,
        title,
        createdAtMS: now,
        expireAtMS: moment().add(30, "days").valueOf(),
        id: h.LowPriorityNotificationDetail.generateId(),
        routerPath: `main/stats/${p.soccerGame.calendarEntryId}/None`,
        type: LowPriorityNotificationDetailType.votingClosed
      };

      h.LowPriorityNotificationDetail.add({
        doc: lp
      });

      notificationBundles.push({
        type: NotificationType.lowPriorityNotification,
        id: a.id,
        accountId: a.accountId,
        triggerEventId,
        pushNotificationData: {
          body: lp.body,
          title: lp.title,
          id: a.id,
          pushNotificationSettingToRespect: PushNotificationSettingToRespect.awards,
          triggerEventId,
          type: NotificationType.lowPriorityNotification,
          routerPath: lp.routerPath,
          lowPriorityNotificationDetailType: lp.type,
          soccerGameId: p.soccerGame.id
        },
        realTimeNotification: {
          d: now,
          id: a.id,
          t: NotificationType.lowPriorityNotification,
          e: moment().add(30, "days").valueOf(),
          lpId: lp.id
        }
      });
    });
  await processNotificationBundles({
    notificationBundles
  });
  return { success: true };
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForVotingClosed.auth = () => {};

function generateBodyAndTitleForVotingClosedNotification(p: {
  accountId: AccountId;
  playerBundles: PrettyPlayerBundle[];
  numVotesMVP: Record<PlayerId, number>;
  numVotesOffense: Record<PlayerId, number>;
  numVotesDefense: Record<PlayerId, number>;
  team: Team;
  locale: string;
}): { body: string; title: string } {
  const baseMsg = translate({ defaultMessage: "See which players were voted as the MVPs.", serverLocale: p.locale });
  let body = baseMsg;
  const playerStringsReceivingVotes: string[] = [];
  const playerBundlesAssociatedWithThisAccount = p.playerBundles.filter(
    pb => pb.playerBundle.managingAccounts && !!pb.playerBundle.managingAccounts[p.accountId]
  );
  playerBundlesAssociatedWithThisAccount.forEach(pb => {
    const playerId = ObjectKeys(pb.playerBundle.derived.linkedPlayers).find(
      pId =>
        pb.playerBundle.derived.linkedPlayers[pId].teamId === p.team.id &&
        pb.playerBundle.derived.linkedPlayers[pId].status === "active"
    );
    if (playerId) {
      const awardsForWhichPlayerReceivedVotes = [];
      !!p.numVotesDefense[playerId] && awardsForWhichPlayerReceivedVotes.push("defensive");
      !!p.numVotesOffense[playerId] && awardsForWhichPlayerReceivedVotes.push("offensive");
      !!p.numVotesMVP[playerId] && awardsForWhichPlayerReceivedVotes.push("overall");
      const playerNameString =
        pb.playerBundle.managingAccounts &&
        pb.playerBundle.managingAccounts[p.accountId]?.type === PlayerBundle__AccountType.selfAthlete
          ? "You"
          : pb.derived.accountInfo.firstName;
      playerStringsReceivingVotes.push(playerNameString);
      if (awardsForWhichPlayerReceivedVotes.length) {
        const awardString =
          awardsForWhichPlayerReceivedVotes.length === 1
            ? `${awardsForWhichPlayerReceivedVotes[0]}`
            : awardsForWhichPlayerReceivedVotes.length === 2
            ? `${awardsForWhichPlayerReceivedVotes.join(` ${translate({ defaultMessage: "and", serverLocale: p.locale })} `)}`
            : `${awardsForWhichPlayerReceivedVotes[0]}, ${awardsForWhichPlayerReceivedVotes[1]}, ${translate({
                defaultMessage: "and",
                serverLocale: p.locale
              })} ${awardsForWhichPlayerReceivedVotes[2]}`;

        body =
          body +
          ` ${translate(
            { defaultMessage: `{playerNameString} received votes for {awardString} MVP!`, serverLocale: p.locale },
            { playerNameString, awardString }
          )}`;
      }
    }
  });

  if (body === baseMsg) {
    body = baseMsg.replace(".", "!");
  }

  let title = translate({ defaultMessage: "Voting Closed!", serverLocale: p.locale });
  if (playerStringsReceivingVotes.length) {
    title =
      title +
      " " +
      (playerStringsReceivingVotes.length === 1
        ? `${playerStringsReceivingVotes[0]}`
        : playerStringsReceivingVotes.length === 2
        ? `${playerStringsReceivingVotes.join(` ${translate({ defaultMessage: "and", serverLocale: p.locale })} `)}`
        : playerStringsReceivingVotes.slice(0, playerStringsReceivingVotes.length - 1).join(", ") +
          `, ${translate({ defaultMessage: "and", serverLocale: p.locale })} ` +
          playerStringsReceivingVotes[playerStringsReceivingVotes.length - 1]) +
      ` ${translate({ defaultMessage: "received votes!", serverLocale: p.locale })}`;
  }
  return { body, title };
}
// i18n certified - complete
