import { TriggerPaymentNotificationProps, triggerForPaymentNotifications } from "./payment-helpers";

export async function notification__server__triggerForPayments(p: TriggerPaymentNotificationProps) {
  // SERVER_ONLY_TOGGLE
  triggerForPaymentNotifications(p);
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForPayments.auth = () => {
  throw new Error("Should only run via cron jobs");
};

// i18n certified - complete
