import {
  AccountId,
  LowPriorityNotificationDetailType,
  NotificationType,
  OrgId,
  OrgRegistration,
  OrgRegistrationAnswerAdditionalStep,
  OrgRegistrationPackageId,
  OrgRegistrationQuestion__AdditionalStep,
  OrgRegistrationStatus,
  OrgRegistrationSuccessMessage,
  OrgSeason,
  OrgSeasonId,
  PlayerBundleId,
  PrettyPlayerBundle,
  PushNotificationSettingToRespect,
  TeamId
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import moment from "moment-timezone";
import { dateFormatters, translate } from "@ollie-sports/i18n";
import { ObjectKeys, OrgEmailRequiredPersonalizations, sendOrgEmail, ensureColorHasHashtag } from "../../utils";
import { NotificationGeneratorInfo__LowPriority, generateAndProcessNotifications } from "./notification.plumbing";
import _ from "lodash";
import { playerBundle__server__getPrettyPlayerBundles } from "../playerBundle";
import { getAccountIdsFromPlayerBundleIdForPaymentNotifications } from "./playerBundle-helpers";
import { COLORS } from "../../constants";

export type IneligibilityReminderData = {
  playerBundleId: PlayerBundleId;
  orgId: OrgId;
  prioritizedRegistrationPackageId: OrgRegistrationPackageId;
  orgSeasonId: OrgSeasonId;
  orgRegistration?: OrgRegistration;
  status: OrgRegistrationStatus;
  registrationDueDateMS: number;
} & ({ type: "reminder"; numDays: number } | { type: "pastDue" });

export async function triggerForIneligibleNotifications(p: { ineligibleNotificationData: IneligibilityReminderData[] }) {
  // SERVER_ONLY_TOGGLE
  const { serverConfig } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  const today = moment().format("YYYY-MM-DD");
  olliePipe.emitEvent({
    type: "metric-send-ineligible-reminders",
    payload: { type: "start-send", numIneligiblePlayers: p.ineligibleNotificationData.length }
  });

  const accountIdsToNotifyByPlayerBundleId = await getAccountIdsFromPlayerBundleIdForPaymentNotifications({
    playerBundleIds: _.uniq(p.ineligibleNotificationData.map(a => a.playerBundleId))
  });

  const allPlayerBundleIds = _.compact(p.ineligibleNotificationData.map(a => a.playerBundleId));

  const allPrettyPlayerBundles = await playerBundle__server__getPrettyPlayerBundles({
    ids: allPlayerBundleIds
  });

  try {
    await generateAndProcessNotifications({
      data: p.ineligibleNotificationData.reduce((acc, val) => {
        const prettyPlayerBundle = allPrettyPlayerBundles.find(ppb => ppb.playerBundle.id === val.playerBundleId);
        const accountIdsToNotify = accountIdsToNotifyByPlayerBundleId[val.playerBundleId];
        if (prettyPlayerBundle) {
          accountIdsToNotify.forEach(accountId => {
            acc.push({
              accountId,
              fetchAccount: true,
              prettyPlayerBundle,
              ...val
            });
          });
        }
        return acc;
      }, [] as (IneligibilityReminderData & { accountId: AccountId; fetchAccount: true; prettyPlayerBundle: PrettyPlayerBundle })[]),
      generateFn: async ({ accountPrivate, data, org, account, orgSettings }) => {
        if (!org) {
          return null;
        }
        const timezone = org.timezone ?? "America/Denver";

        const type: "upcoming" | "pastDue" = data.registrationDueDateMS > moment().valueOf() ? "upcoming" : "pastDue";

        const ret: NotificationGeneratorInfo__LowPriority = {
          type: "lowPriorityNotification",
          notificationType: NotificationType.lowPriorityNotification,
          idempotencyKey: ["ineligibilty-reminder", data.playerBundleId, data.orgSeasonId, today].join("|")
        };

        const registerEmailButton = `<div style='margin-top: 16px'><a href="${`${serverConfig.httpWebappRoot}/org/${org.id}/register/${data.playerBundleId}/${data.orgSeasonId}`}" style="display: inline-block; padding: 10px 20px; color: white; background-color: ${
          orgSettings?.primaryColor ? ensureColorHasHashtag(orgSettings.primaryColor) : COLORS.green
        }; text-decoration: none; border-radius: 5px; font-size: 16px;">${
          data.status === OrgRegistrationStatus.incomplete
            ? `${translate({
                defaultMessage: "View Incomplete Steps",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : translate({
                defaultMessage: "Register Now",
                serverLocale: accountPrivate.communicationLocale
              })
        }</a></div>`;

        const title =
          data.status === OrgRegistrationStatus.bad
            ? `${org.shortName}: ${translate({
                defaultMessage: "Ineligible Player Notice",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : data.status === OrgRegistrationStatus.incomplete
            ? `${org.shortName}: ${translate({
                defaultMessage: "Incomplete Registration",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : type === "upcoming"
            ? `${org.shortName}: ${translate({
                defaultMessage: "Registration Due Soon",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : `${org.shortName}: ${translate({
                defaultMessage: "Ineligible Player Notice",
                serverLocale: accountPrivate.communicationLocale
              })}`;
        const body =
          data.status === OrgRegistrationStatus.bad
            ? `${translate(
                {
                  defaultMessage: `{playerName} is currently ineligible for {orgName}. This is most likely because of a failed or missed payment. Please check your payment history or update your payment method and try to process the payment again.`,
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  playerName: `${data.prettyPlayerBundle.derived.accountInfo.firstName}`,
                  orgName: org.name
                }
              )}`
            : data.status === OrgRegistrationStatus.incomplete
            ? `${org.shortName}: ${translate(
                {
                  defaultMessage: `{playerName} has not completed all of the required steps to be registered for {orgName}. Click here to view what steps are incomplete.`,
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  playerName: `${data.prettyPlayerBundle.derived.accountInfo.firstName}`,
                  orgName: org.name
                }
              )}`
            : type === "upcoming"
            ? translate(
                {
                  defaultMessage:
                    "{playerName} needs to be registered for {orgName} by {dueDateMS} in order to be considered eligible and avoid any possible registration late fees. Click here to complete the registration now.",
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  playerName: `${data.prettyPlayerBundle.derived.accountInfo.firstName}`,
                  orgName: org.name,
                  dueDateMS: dateFormatters.mm_dd_yyyy(
                    moment.tz(data.registrationDueDateMS, timezone),
                    accountPrivate.communicationLocale
                  )
                }
              )
            : translate(
                {
                  defaultMessage:
                    "{playerName} has not registered for {orgName}. The due date has passed, so the player may be considered ineligible by the club until their registration is complete. Click here to complete the registration now.",
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  playerName: `${data.prettyPlayerBundle.derived.accountInfo.firstName}`,
                  orgName: org.name
                }
              );
        const emailSubject =
          data.status === OrgRegistrationStatus.bad
            ? `${org.name}: ${translate({
                defaultMessage: "Ineligible Player Notice",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : data.status === OrgRegistrationStatus.incomplete
            ? `${org.shortName}: ${translate({
                defaultMessage: "Incomplete Registration",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : type === "upcoming"
            ? `${org.name}: ${translate({
                defaultMessage: "Registration Due Soon",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : `${org.name}: ${translate({
                defaultMessage: "Ineligible Player Notice",
                serverLocale: accountPrivate.communicationLocale
              })}`;
        const emailBody =
          data.status === OrgRegistrationStatus.bad
            ? `${translate(
                {
                  defaultMessage: `{playerName} is currently ineligible for {orgName}. This is most likely because of a failed or missed payment. Please check your payment history in the app or update your payment method in the app and try to process the payment again.`,
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  playerName: `${data.prettyPlayerBundle.derived.accountInfo.firstName}`,
                  orgName: org.name
                }
              )}`
            : data.status === OrgRegistrationStatus.incomplete
            ? `${org.shortName}: ${translate(
                {
                  defaultMessage: `{playerName} has not completed all of the required steps to be registered for {orgName}. Click the button below to view what steps are incomplete.`,
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  playerName: `${data.prettyPlayerBundle.derived.accountInfo.firstName}`,
                  orgName: org.name
                }
              )}${registerEmailButton}`
            : type === "upcoming"
            ? `${translate(
                {
                  defaultMessage: `{playerName} needs to be registered for {orgName} by {dueDateMS} in order to be considered eligible and avoid any possible registration late fees. Click the button below to complete the registration now.`,
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  playerName: `${data.prettyPlayerBundle.derived.accountInfo.firstName}`,
                  orgName: org.name,
                  dueDateMS: dateFormatters.mm_dd_yyyy(
                    moment.tz(data.registrationDueDateMS, timezone),
                    accountPrivate.communicationLocale
                  )
                }
              )}${registerEmailButton}`
            : `${translate(
                {
                  defaultMessage: `{playerName} has not registered for {orgName}. The due date has passed, so the player may be considered ineligible by the club until their registration is complete. Click the button below to complete the registration now.`,
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  playerName: `${data.prettyPlayerBundle.derived.accountInfo.firstName}`,
                  orgName: org.name
                }
              )}${registerEmailButton}`;

        const routerPath = `main/tools/myRegistration`;

        const expireAtMS = moment().add(30, "days").valueOf();

        ret.lowPriorityNotificationDetail = {
          type:
            data.type === "pastDue" || data.status === OrgRegistrationStatus.bad
              ? LowPriorityNotificationDetailType.ineligibleNotice
              : LowPriorityNotificationDetailType.upcomingIneligibilty,
          expireAtMS,
          routerPath,
          title,
          body
        };

        ret.pushNotificationData = {
          title,
          body,
          lowPriorityNotificationDetailType:
            data.type === "pastDue" || data.status === OrgRegistrationStatus.bad
              ? LowPriorityNotificationDetailType.ineligibleNotice
              : LowPriorityNotificationDetailType.upcomingIneligibilty,
          pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
          routerPath
        };

        ret.realTimeNotification = {
          e: expireAtMS
        };

        if (account) {
          ret.emailData = {
            type: "org",
            orgEmailPersonalization: {
              name: account.firstName,
              email: account.email,
              subject: emailSubject,
              message: emailBody
            },
            orgId: data.orgId,
            orgEmailReplyToEmailAddress: orgSettings?.registrationSettings?.defaultReplyToEmailAddress
            // orgEmailReplyToName: NATE TODO
          };
        }

        olliePipe.emitEvent({
          type: "metric-send-ineligible-reminders",
          payload: { type: "single-data", ret }
        });
        return ret;
      }
    });
  } catch (e) {
    olliePipe.emitEvent({
      type: "metric-send-ineligible-reminders",
      payload: { type: "error", error: e }
    });
  }

  olliePipe.emitEvent({
    type: "metric-send-ineligible-reminders",
    payload: { type: "done-sending", numIneligiblePlayers: p.ineligibleNotificationData.length }
  });

  // SERVER_ONLY_TOGGLE
}

export async function triggerForTeamAndOrgIneligibleNotifications(p: { orgId: OrgId; playerBundleIds: PlayerBundleId[] }) {
  const { appOllieFirestoreV2: h, serverConfig } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  const [org, orgSettings, teamsData, prettyPlayerBundles] = await Promise.all([
    h.Org.getDoc(p.orgId),
    h.OrgSettings.getDoc(p.orgId),
    h.Team.query({ where: [{ orgId: ["==", p.orgId] }] }),
    playerBundle__server__getPrettyPlayerBundles({ ids: p.playerBundleIds })
  ]);
  olliePipe.emitEvent({
    type: "metric-send-team-and-org-ineligible-report",
    payload: { type: "start-sending-for-org", orgId: p.orgId, numPlayerBundleIds: p.playerBundleIds.length }
  });

  if (!org) {
    return;
  }

  const teams = teamsData.docs;
  const allTeamIdsMap = teams.reduce((acc, team) => {
    acc[team.id] = true;
    return acc;
  }, {} as Record<TeamId, true>);

  const ineligiblePlayerBundleIdsByTeamId: Record<TeamId, PlayerBundleId[]> = prettyPlayerBundles.reduce((acc, playerBundle) => {
    const allTeamIdsForPlayerBundle = Object.values(playerBundle.playerBundle.derived.linkedPlayers ?? {})
      .filter(a => a.status === "active" && !a.guestPlayerExirationMS && allTeamIdsMap[a.teamId])
      .map(a => a.teamId);
    allTeamIdsForPlayerBundle.map(teamId => {
      if (!acc[teamId]) {
        acc[teamId] = [playerBundle.playerBundle.id];
      } else {
        acc[teamId] = [...acc[teamId], playerBundle.playerBundle.id];
      }
    });
    return acc;
  }, {} as Record<TeamId, PlayerBundleId[]>);

  const allAccountIdsWithType: (
    | { accountId: AccountId; type: "org" }
    | { accountId: AccountId; type: "team"; teamId: TeamId }
  )[] = [
    ...(ObjectKeys(org?.accounts ?? {}).map(aId => {
      return { accountId: aId, type: "org" };
    }) as { accountId: AccountId; type: "org" }[]),
    ...(_.flatten(
      teams
        .filter(t => ineligiblePlayerBundleIdsByTeamId[t.id]?.length)
        .map(t =>
          ObjectKeys(t.accounts)
            .filter(aId => t.accounts[aId]?.roles.staff)
            .map(aId => ({ accountId: aId, teamId: t.id }))
        )
    ).map(a => {
      return { accountId: a.accountId, teamId: a.teamId, type: "team" };
    }) as { accountId: AccountId; type: "team"; teamId: TeamId }[])
  ];
  const [accountsData, accountPrivateData] = await Promise.all([
    h.Account.getDocs(allAccountIdsWithType.map(a => a.accountId)),
    h.AccountPrivate.getDocs(allAccountIdsWithType.map(a => a.accountId))
  ]);

  const accounts = _.compact(accountsData);
  const accountPrivates = _.compact(accountPrivateData);

  const allAccountIdsWithTypeAndMetadata = _.compact(
    allAccountIdsWithType.map(a => {
      const account = accounts.find(acc => acc.id === a.accountId);
      const accountPrivate = accountPrivates.find(acc => acc.id === a.accountId);
      if (account && accountPrivate) {
        return {
          ...a,
          account,
          accountPrivate
        };
      }
      return null;
    })
  );

  const emailPersonalizations: OrgEmailRequiredPersonalizations[] = _.compact(
    allAccountIdsWithTypeAndMetadata.map(data => {
      const subject = `${org.name} - ${translate({
        defaultMessage: "Ineligible Player Report",
        serverLocale: data.accountPrivate.communicationLocale
      })}`;
      if (data.type === "org") {
        return {
          email: data.account.email,
          name: data.account.firstName,
          subject,
          message: `<div><p>${translate({
            defaultMessage:
              "The following teams have been notified about players who are ineligible for upcoming games or practices.",
            serverLocale: data.accountPrivate.communicationLocale
          })}</p><ul>${_.compact(
            Object.keys(ineligiblePlayerBundleIdsByTeamId).map(teamId => {
              const team = teams.find(t => t.id === teamId);
              if (team) {
                return `<li><a href="${serverConfig.httpWebappRoot}/app/org/${p.orgId}/teams/${team.id}?tab=players">${
                  team.name
                }</a> (${translate(
                  {
                    defaultMessage: "{numPlayers} {numPlayers, plural, one {player} other {players}}",
                    serverLocale: data.accountPrivate.communicationLocale
                  },
                  { numPlayers: ineligiblePlayerBundleIdsByTeamId[teamId].length }
                )})</li>`;
              }
              return null;
            })
          ).join("")}</ul><p>${translate({
            defaultMessage:
              "Team staff have been asked not to allow ineligible players to participate in their next game or practice.",
            serverLocale: data.accountPrivate.communicationLocale
          })}</p></div>`
        };
      } else {
        const team = teams.find(t => t.id === data.teamId);
        if (!team) {
          return null;
        }
        const prettyPlayerBundlesOnTeam = _.compact(
          Object.values(ineligiblePlayerBundleIdsByTeamId[team.id] ?? {}).map(playerBundleId =>
            prettyPlayerBundles.find(ppb => ppb.playerBundle.id === playerBundleId)
          )
        );
        const numPlayers = prettyPlayerBundlesOnTeam.length;
        if (!numPlayers) {
          return null;
        }
        return {
          email: data.account.email,
          name: data.account.firstName,
          subject,
          message: `<div><p>${translate(
            {
              defaultMessage:
                "This message is to inform you that {teamLink} has {numPlayers} ineligible {numPlayers, plural, one {player} other {players}}.",
              serverLocale: data.accountPrivate.communicationLocale
            },
            {
              teamLink: `<a href="${serverConfig.httpWebappRoot}/app/org/${p.orgId}/teams/${team.id}?tab=players">${team.name}</a>`,
              numPlayers
            }
          )}</p><ul>${prettyPlayerBundlesOnTeam
            .map(ppb => {
              return `<li>${ppb.derived.accountInfo.firstName} ${ppb.derived.accountInfo.lastName}</li>`;
            })
            .join("")}</ul><p>${translate({
            defaultMessage:
              "Please make sure the ineligible player does not participate in the team's upcoming games or practices without completing eligibility requirements beforehand.",
            serverLocale: data.accountPrivate.communicationLocale
          })}</p></div>`
        };
      }
    })
  );
  olliePipe.emitEvent({
    type: "metric-send-team-and-org-ineligible-report",
    payload: { type: "about-to-send-for-org", orgId: p.orgId, emails: emailPersonalizations.map(a => a.email) }
  });

  await sendOrgEmail({
    orgId: org.id,
    personalizations: emailPersonalizations,
    org: org,
    orgSettings: orgSettings ?? undefined
  });
  olliePipe.emitEvent({
    type: "metric-send-team-and-org-ineligible-report",
    payload: { type: "done-sending-for-org", orgId: p.orgId }
  });
}

// i18n certified - complete
