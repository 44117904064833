import {
  ACHFailureCodes,
  AccountId,
  LowPriorityNotificationDetailType,
  NotificationType,
  OrgId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgPaymentInvoiceCredit,
  OrgPaymentInvoiceDefault,
  OrgPaymentInvoiceFailed,
  OrgPaymentInvoiceFailedECheck,
  OrgPaymentType,
  PaymentMethodType,
  PushNotificationSettingToRespect
} from "@ollie-sports/models";
import { NotificationGeneratorInfo__LowPriority, generateAndProcessNotifications } from "./notification.plumbing";
import { getAccountIdsFromPlayerBundleIdForPaymentNotifications } from "./playerBundle-helpers";
import { getUniversalHelpers } from "../../helpers";
import moment, { locale } from "moment";
import { dateFormatters, translateServer } from "@ollie-sports/i18n";
import {
  formatMoneyCentsToDollarCentPrettyString,
  getIndividualOrgInvoiceAmountDetails,
  ensureColorHasHashtag,
  getPaymentMethodToUse,
  getPaymentMethodString
} from "../../utils";
import { COLORS, PRETTY_PAYMENT_ERROR_CODES } from "../../constants";
import { playerBundle__server__getPrettyPlayerBundle } from "../playerBundle";
import { getDefaultPaymentAccount } from "../../compute/account.compute";

type PropsFailed = {
  type: "failed";
  failType: "failed-scheduled";
  orgPayment: OrgPaymentInvoiceFailed;
  orgInvoice: OrgInvoice;
};

type PropsFailedECheck = {
  type: "failed";
  failType: "failed-echeck";
  orgPayment: OrgPaymentInvoiceFailedECheck;
  orgInvoice: OrgInvoice;
};

type PropsSoonToBeCharged = {
  type: "soonToBeCharged";
  orgInvoiceData: {
    orgInvoiceChild: OrgInvoiceChild;
    orgInvoiceParent: OrgInvoiceParent;
    orgPaymentInvoiceCredits: OrgPaymentInvoiceCredit[];
  }[];
};

type PropsSuccessfulParentOrgInvoicePayment = {
  type: "successfulParentOrgInvoicePayment";
  orgInvoiceParent: OrgInvoiceParent;
  orgPayment: OrgPaymentInvoiceDefault;
  doesPaymentPlanExist: boolean;
};

type PropsSuccessfulChildOrgInvoicePayment = {
  type: "successfulChildOrgInvoicePayment";
  orgInvoiceChild: OrgInvoiceChild;
  orgPayment: OrgPaymentInvoiceDefault;
};

type PropsUpdatedInstallmentAmount = {
  type: "updatedInstallmentAmount";
  orgInvoice: OrgInvoice;
  oldAmountCents: number;
  newAmountCents: number;
};
type PropsNewInstallmentAmount = {
  type: "newInstallmentAmount";
  orgInvoice: OrgInvoice;
};
export type TriggerPaymentNotificationProps =
  | PropsFailed
  | PropsFailedECheck
  | PropsSoonToBeCharged
  | PropsSuccessfulParentOrgInvoicePayment
  | PropsSuccessfulChildOrgInvoicePayment
  | PropsUpdatedInstallmentAmount
  | PropsNewInstallmentAmount;

export async function triggerForPaymentNotifications(p: TriggerPaymentNotificationProps) {
  // SERVER_ONLY_TOGGLE
  const { olliePipe, ollieFirestoreV2: h } = getUniversalHelpers();
  olliePipe.emitEvent({
    type: "metric-send-payment-notifications",
    payload: { type: "start", props: p }
  });
  const playerBundleIds =
    p.type == "failed"
      ? [p.orgPayment.playerBundleId]
      : p.type === "successfulParentOrgInvoicePayment"
      ? [p.orgInvoiceParent.playerBundleId]
      : p.type === "updatedInstallmentAmount" || p.type === "newInstallmentAmount"
      ? [p.orgInvoice.playerBundleId]
      : p.type === "successfulChildOrgInvoicePayment"
      ? [p.orgInvoiceChild.playerBundleId]
      : p.orgInvoiceData.map(a => a.orgInvoiceChild.playerBundleId);
  const accountIdsToNotifyByPlayerBundleId = await getAccountIdsFromPlayerBundleIdForPaymentNotifications({ playerBundleIds });
  const today = moment().format("YYYY-MM-DD");

  if (p.type === "failed") {
    const accountIdsToNotify = accountIdsToNotifyByPlayerBundleId[p.orgPayment.playerBundleId];
    olliePipe.emitEvent({
      type: "metric-send-payment-notifications",
      payload: { type: "send-failed", accountIdsToNotify }
    });
    try {
      await generateAndProcessNotifications({
        data: accountIdsToNotify.map(accountId => {
          return {
            accountId,
            orgPayment: p.orgPayment,
            orgId: p.orgPayment.orgId,
            orgInvoice: p.orgInvoice,
            fetchAccount: true
          };
        }),
        generateFn: async ({ accountPrivate, data, org, account, orgSettings }) => {
          if (!org) {
            return null;
          }

          const ret: NotificationGeneratorInfo__LowPriority = {
            type: "lowPriorityNotification",
            notificationType: NotificationType.lowPriorityNotification,
            idempotencyKey: ["failed-payment", data.orgPayment.id].join("|")
          };

          const prettyFailureType =
            p.failType === "failed-scheduled"
              ? translateServer({
                  defaultMessage: "Failed Payment",
                  serverLocale: accountPrivate.communicationLocale
                })
              : translateServer({
                  defaultMessage: "Rejected Payment",
                  serverLocale: accountPrivate.communicationLocale
                });

          const amount = formatMoneyCentsToDollarCentPrettyString(
            p.orgPayment.amountCents + (p.orgPayment.lateFeeAmountCents ?? 0) + (p.orgPayment.processingFeeAmountCents ?? 0)
          );

          const body =
            p.failType === "failed-scheduled"
              ? translateServer(
                  {
                    defaultMessage: "A scheduled payment of {amount} failed. Click here to manage the payment.",
                    serverLocale: accountPrivate.communicationLocale
                  },
                  { amount }
                )
              : translateServer(
                  {
                    defaultMessage:
                      "{amount} e-check posted on {date} failed ({failureReason})! Click here to manage the payment.",
                    serverLocale: accountPrivate.communicationLocale
                  },
                  {
                    amount,
                    date: dateFormatters.m_d_yy_t_tt_a(p.orgPayment.createdAtMS, accountPrivate.communicationLocale),
                    failureReason:
                      PRETTY_PAYMENT_ERROR_CODES[p.orgPayment.failureCode || ""]?.(accountPrivate.communicationLocale) ||
                      p.orgPayment.failureFallbackText ||
                      translateServer({ defaultMessage: "Unknown", serverLocale: accountPrivate.communicationLocale })
                  }
                );

          const title = `${org.shortName} - ${prettyFailureType}`;
          const emailSubject = `${org.name} - ${prettyFailureType}`;

          const failureReason =
            PRETTY_PAYMENT_ERROR_CODES[p.orgPayment.failureCode || ""]?.(accountPrivate.communicationLocale) ||
            p.orgPayment.failureFallbackText ||
            translateServer({ defaultMessage: "Unknown Error", serverLocale: accountPrivate.communicationLocale });

          const emailBodyTop =
            p.failType === "failed-scheduled"
              ? translateServer(
                  {
                    defaultMessage: "A scheduled payment of {amount} failed.",
                    serverLocale: accountPrivate.communicationLocale
                  },
                  { amount }
                )
              : translateServer(
                  {
                    defaultMessage: "An e-check payment of {amount} posted {date} was rejected by the bank.",
                    serverLocale: accountPrivate.communicationLocale
                  },
                  {
                    amount,
                    date: dateFormatters.m_d_yy_t_tt_a(p.orgPayment.createdAtMS, accountPrivate.communicationLocale)
                  }
                );

          const learnMoreMessage =
            p.failType === "failed-scheduled"
              ? translateServer({
                  defaultMessage:
                    "You can find more information on why the payment failed inside the Ollie app by going to Tools > Payments. You can also update your payment information in the Ollie app by going to Tools > Payment Methods.",
                  serverLocale: accountPrivate.communicationLocale
                })
              : translateServer({
                  defaultMessage:
                    "You may be able to find more information on why the payment failed by talking with your bank or going inside the Ollie app by going to Tools > Payments. You can also update your payment information in the Ollie app by going to Tools > Payment Methods.",
                  serverLocale: accountPrivate.communicationLocale
                });

          const emailBody = `${emailBodyTop}\n\n${
            p.orgPayment.paymentMethodSnapshot
              ? `<strong>${
                  translateServer.common(accountPrivate.communicationLocale).PaymentMethod
                }:</strong> ${getPaymentMethodString({
                  locale: accountPrivate.communicationLocale,
                  paymentMethod: p.orgPayment.paymentMethodSnapshot,
                  includeName: true
                })}\n\n`
              : ""
          }${
            p.orgPayment.nmiPaymentResponseInfo.responseCode
              ? `<strong>${translateServer({
                  defaultMessage: "Failure Reason",
                  serverLocale: accountPrivate.communicationLocale
                })}:</strong> ${failureReason}\n\n`
              : ""
          }${learnMoreMessage}`;

          const parentInvoiceId =
            "parentOrgInvoiceId" in data.orgInvoice ? data.orgInvoice.parentOrgInvoiceId : data.orgInvoice.id;

          const routerPath = `main/tools/myPayments/${parentInvoiceId}`;

          const expireAtMS = moment().add(30, "days").valueOf();

          ret.lowPriorityNotificationDetail = {
            type: LowPriorityNotificationDetailType.failedAutoPayment,
            expireAtMS,
            routerPath,
            title,
            body
          };

          ret.pushNotificationData = {
            title,
            body,
            lowPriorityNotificationDetailType: LowPriorityNotificationDetailType.failedAutoPayment,
            pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
            routerPath
          };

          ret.realTimeNotification = {
            e: expireAtMS
          };

          if (account) {
            ret.emailData = {
              type: "org",
              orgEmailPersonalization: {
                name: account.firstName,
                email: account.email,
                subject: emailSubject,
                message: emailBody
              },
              orgId: data.orgId,
              orgEmailReplyToEmailAddress: orgSettings?.registrationSettings?.defaultReplyToEmailAddress
              // orgEmailReplyToName: NATE TODO
            };
          }
          olliePipe.emitEvent({
            type: "metric-send-payment-notifications",
            payload: { type: "single-data-failed", ret }
          });

          return ret;
        }
      });
    } catch (e) {
      olliePipe.emitEvent({
        type: "metric-send-payment-notifications",
        payload: { type: "send-error-failed", accountIdsToNotify }
      });
    }
  } else if (p.type === "soonToBeCharged") {
    try {
      await generateAndProcessNotifications({
        data: p.orgInvoiceData.reduce(
          (acc, val) => {
            const accountIdsToNotify = accountIdsToNotifyByPlayerBundleId[val.orgInvoiceChild.playerBundleId];
            olliePipe.emitEvent({
              type: "metric-send-payment-notifications",
              payload: { type: "send-soon-to-be-charged", accountIdsToNotify, data: val }
            });
            accountIdsToNotify.forEach(accountId => {
              acc.push({
                accountId,
                orgId: val.orgInvoiceChild.orgId,
                fetchAccount: true,
                orgInvoiceChild: val.orgInvoiceChild,
                orgInvoiceParent: val.orgInvoiceParent,
                orgPaymentInvoiceCredits: val.orgPaymentInvoiceCredits
              });
            });
            return acc;
          },
          [] as {
            accountId: AccountId;
            orgInvoiceChild: OrgInvoiceChild;
            orgInvoiceParent: OrgInvoiceParent;
            orgId: OrgId;
            fetchAccount: true;
            orgPaymentInvoiceCredits: OrgPaymentInvoiceCredit[];
          }[]
        ),
        generateFn: async ({ accountPrivate, data, org, account, orgSettings }) => {
          if (!org) {
            return null;
          }

          const accountSecret = await h.AccountSecret.getDoc(accountPrivate.id);

          const defaultPaymentMethod = getDefaultPaymentAccount(Object.values(accountSecret?.paymentMethodsById ?? {}));

          if (!defaultPaymentMethod) {
            // Payment TODO
            return null;
          }

          const ret: NotificationGeneratorInfo__LowPriority = {
            type: "lowPriorityNotification",
            notificationType: NotificationType.lowPriorityNotification,
            idempotencyKey: ["scheduled-payment-reminder", data.orgInvoiceChild.id, today].join("|")
          };

          const orgInvoiceDetails = getIndividualOrgInvoiceAmountDetails({
            orgInvoice: data.orgInvoiceChild,
            orgPayments: data.orgPaymentInvoiceCredits,
            feeDetails: orgSettings?.customFeeDetails?.[defaultPaymentMethod.type],
            paymentMethodType: defaultPaymentMethod.type
          });

          const responsibleAccountSecret = await h.AccountSecret.getDoc(data.orgInvoiceChild.accountIdScheduledToPay);

          let paymentMethodString = "";
          if (responsibleAccountSecret && responsibleAccountSecret.paymentMethodsById) {
            const paymentMethodToUse = getPaymentMethodToUse(responsibleAccountSecret.paymentMethodsById);

            if (paymentMethodToUse) {
              paymentMethodString = getPaymentMethodString({
                locale: accountPrivate.communicationLocale,
                paymentMethod: paymentMethodToUse,
                includeName: true
              });
            }
          }

          const emailSubject = `${org.name} - ${translateServer({
            defaultMessage: "Auto Payment Reminder",
            serverLocale: accountPrivate.communicationLocale
          })}`;
          const emailBody = `${translateServer(
            {
              defaultMessage: "Your scheduled payment of {amount} will be automatically processed tomorrow.",
              serverLocale: accountPrivate.communicationLocale
            },
            {
              amount: formatMoneyCentsToDollarCentPrettyString(orgInvoiceDetails.totalRemainingAmountDueCents)
            }
          )}${
            paymentMethodString
              ? ` ${translateServer({
                  defaultMessage: "The following payment method will be charged:",
                  serverLocale: accountPrivate.communicationLocale
                })}\n\n${paymentMethodString}`
              : ""
          }\n\n${translateServer({
            defaultMessage: "You can view your upcoming payments inside the Ollie app by going to Tools > Payments.",
            serverLocale: accountPrivate.communicationLocale
          })}`;

          if (account) {
            ret.emailData = {
              type: "org",
              orgEmailPersonalization: {
                name: account.firstName,
                email: account.email,
                subject: emailSubject,
                message: emailBody
              },
              orgId: data.orgId,
              orgEmailReplyToEmailAddress: orgSettings?.registrationSettings?.defaultReplyToEmailAddress
              // orgEmailReplyToName: NATE TODO
            };
          }
          olliePipe.emitEvent({
            type: "metric-send-payment-notifications",
            payload: { type: "single-data-soon-to-be-charged", ret }
          });

          return ret;
        }
      });
    } catch (e) {
      olliePipe.emitEvent({
        type: "metric-send-payment-notifications",
        payload: { type: "error-send-soon-to-be-charged" }
      });
    }
  } else if (p.type === "successfulParentOrgInvoicePayment") {
    const accountIdsToNotify = accountIdsToNotifyByPlayerBundleId[p.orgPayment.playerBundleId];
    olliePipe.emitEvent({
      type: "metric-send-payment-notifications",
      payload: { type: "send-successful-parent", accountIdsToNotify }
    });
    try {
      await generateAndProcessNotifications({
        data: accountIdsToNotify.map(accountId => {
          return {
            accountId,
            orgPayment: p.orgPayment,
            orgId: p.orgPayment.orgId,
            orgInvoiceParent: p.orgInvoiceParent,
            fetchAccount: true
          };
        }),
        generateFn: async ({ accountPrivate, data, org, account, orgSettings }) => {
          if (!org) {
            return null;
          }

          const ret: NotificationGeneratorInfo__LowPriority = {
            type: "lowPriorityNotification",
            notificationType: NotificationType.lowPriorityNotification,
            idempotencyKey: ["successful-parent-org-invoice-payment", data.orgPayment.id].join("|")
          };

          let emailSubject = `${org.name} - `;
          let emailBody = ``;

          const isZeroDollarPayment = p.orgPayment.amountCents === 0;
          const doesPaymentPlanExist = p.doesPaymentPlanExist;
          if (isZeroDollarPayment && !doesPaymentPlanExist) {
            // This shouldn't happen
            return null;
          }
          if (p.orgInvoiceParent.type === OrgInvoiceTypes.manual) {
            emailSubject =
              emailSubject +
              `${
                isZeroDollarPayment
                  ? translateServer({
                      defaultMessage: "Payment Plan Confirmation",
                      serverLocale: accountPrivate.communicationLocale
                    })
                  : translateServer({ defaultMessage: "Payment Confimation", serverLocale: accountPrivate.communicationLocale })
              }`;
            emailBody = `<div>${[
              !isZeroDollarPayment
                ? `<p>${translateServer(
                    {
                      defaultMessage: "We have received your {amount} payment.",
                      serverLocale: accountPrivate.communicationLocale
                    },
                    { amount: `${formatMoneyCentsToDollarCentPrettyString(p.orgPayment.amountCents)}` }
                  )}</p>`
                : "",
              p.orgPayment.paymentMethodSnapshot?.type === PaymentMethodType.bank
                ? `<p>${translateServer({
                    defaultMessage:
                      "Please note that your e-check has not yet settled and there is a chance this payment may be reversed.",
                    serverLocale: accountPrivate.communicationLocale
                  })}</p>`
                : "",
              doesPaymentPlanExist
                ? `<p>${translateServer({
                    defaultMessage: "You can view the upcoming payments on your payment plan in the Ollie app on the Tools tab.",
                    serverLocale: accountPrivate.communicationLocale
                  })}</p>`
                : ""
            ]
              .filter(a => a)
              .join(" ")}</div>`;
          } else if (p.orgInvoiceParent.type === OrgInvoiceTypes.registration) {
            // Sending a notification already in orgInvoice__payOrgInvoiceAndPotentiallyGeneratePaymentPlanInstallmentInvoices
            return null;
          } else {
            return null;
          }

          if (account) {
            ret.emailData = {
              type: "org",
              orgEmailPersonalization: {
                name: account.firstName,
                email: account.email,
                subject: emailSubject,
                message: emailBody
              },
              orgId: data.orgId,
              orgEmailReplyToEmailAddress: orgSettings?.registrationSettings?.defaultReplyToEmailAddress
              // orgEmailReplyToName: NATE TODO
            };
          }
          olliePipe.emitEvent({
            type: "metric-send-payment-notifications",
            payload: { type: "single-data-successful-parent", ret }
          });

          return ret;
        }
      });
    } catch (e) {
      olliePipe.emitEvent({
        type: "metric-send-payment-notifications",
        payload: { type: "error-send-successful-parent", accountIdsToNotify }
      });
    }
  } else if (p.type === "updatedInstallmentAmount") {
    const accountIdsToNotify = accountIdsToNotifyByPlayerBundleId[p.orgInvoice.playerBundleId];
    olliePipe.emitEvent({
      type: "metric-send-payment-notifications",
      payload: { type: "send-updated-installment", accountIdsToNotify }
    });
    const prettyPlayerBundle = await playerBundle__server__getPrettyPlayerBundle({
      id: p.orgInvoice.playerBundleId
    });
    try {
      await generateAndProcessNotifications({
        data: accountIdsToNotify.map(accountId => {
          return {
            accountId,
            orgId: p.orgInvoice.orgId,
            orgInvoice: p.orgInvoice,
            fetchAccount: true,
            oldAmountCents: p.oldAmountCents,
            newAmountCents: p.newAmountCents
          };
        }),
        generateFn: async ({ accountPrivate, data, org, account, orgSettings }) => {
          if (!org) {
            return null;
          }

          const ret: NotificationGeneratorInfo__LowPriority = {
            type: "lowPriorityNotification",
            notificationType: NotificationType.lowPriorityNotification,
            idempotencyKey: ["successful-updated-installment", data.orgInvoice.id].join("|")
          };

          let emailSubject = `${org.name} - `;
          let emailBody = ``;
          emailSubject =
            emailSubject +
            `${translateServer({
              defaultMessage: "Scheduled Payment Updated",
              serverLocale: accountPrivate.communicationLocale
            })}`;
          emailBody = `<div>${`<p>${translateServer({
            defaultMessage: "A scheduled payment has been updated.",
            serverLocale: accountPrivate.communicationLocale
          })}</p>`}

          ${
            "autoChargeDateMS" in p.orgInvoice
              ? `<div style='margin-bottom: 4px;'>
                  <strong>${translateServer.common(accountPrivate.communicationLocale).Scheduled}:</strong>
                  ${dateFormatters.mm_dd_yyyy(moment(p.orgInvoice.autoChargeDateMS).toDate(), accountPrivate.communicationLocale)}
                </div>`
              : ""
          }

          <div style='margin-bottom: 4px;'><strong>${translateServer({
            defaultMessage: "Old Amount",
            serverLocale: accountPrivate.communicationLocale
          })}:</strong> ${formatMoneyCentsToDollarCentPrettyString(
            p.oldAmountCents
          )}</div><div style='margin-bottom: 4px;'><strong>${translateServer({
            defaultMessage: "New Amount",
            serverLocale: accountPrivate.communicationLocale
          })}:</strong> ${formatMoneyCentsToDollarCentPrettyString(p.newAmountCents)}</div>${`<p>${translateServer({
            defaultMessage: "You can view the all payment details in the Ollie app on the Tools tab.",
            serverLocale: accountPrivate.communicationLocale
          })}</p>`}</div>`;

          if (account) {
            ret.emailData = {
              type: "org",
              orgEmailPersonalization: {
                name: account.firstName,
                email: account.email,
                subject: emailSubject,
                message: emailBody
              },
              orgId: data.orgId,
              orgEmailReplyToEmailAddress: orgSettings?.registrationSettings?.defaultReplyToEmailAddress
              // orgEmailReplyToName: NATE TODO
            };
          }
          olliePipe.emitEvent({
            type: "metric-send-payment-notifications",
            payload: { type: "single-data-updated-installment", ret }
          });

          return ret;
        }
      });
    } catch (e) {
      olliePipe.emitEvent({
        type: "metric-send-payment-notifications",
        payload: { type: "error-send-successful-parent", accountIdsToNotify }
      });
    }
  } else if (p.type === "newInstallmentAmount") {
    const accountIdsToNotify = accountIdsToNotifyByPlayerBundleId[p.orgInvoice.playerBundleId];
    olliePipe.emitEvent({
      type: "metric-send-payment-notifications",
      payload: { type: "send-new-installment", accountIdsToNotify }
    });
    try {
      await generateAndProcessNotifications({
        data: accountIdsToNotify.map(accountId => {
          return {
            accountId,
            orgId: p.orgInvoice.orgId,
            orgInvoice: p.orgInvoice,
            fetchAccount: true
          };
        }),
        generateFn: async ({ accountPrivate, data, org, account, orgSettings }) => {
          if (!org) {
            return null;
          }

          const ret: NotificationGeneratorInfo__LowPriority = {
            type: "lowPriorityNotification",
            notificationType: NotificationType.lowPriorityNotification,
            idempotencyKey: ["successful-new-installment", data.orgInvoice.id].join("|")
          };

          let emailSubject = `${org.name} - `;
          let emailBody = ``;
          emailSubject =
            emailSubject +
            `${translateServer({ defaultMessage: "New Scheduled payment", serverLocale: accountPrivate.communicationLocale })}`;
          emailBody = `<div>${`<p>${translateServer({
            defaultMessage: "A new scheduled payment has been created.",
            serverLocale: accountPrivate.communicationLocale
          })}</p>`}<div style='margin-bottom: 4px;'><strong>${
            translateServer.common(accountPrivate.communicationLocale).Amount
          }:</strong> ${formatMoneyCentsToDollarCentPrettyString(p.orgInvoice.amountDueCents)}</div>

          ${
            "autoChargeDateMS" in p.orgInvoice
              ? `<div style='margin-bottom: 4px;'>
                  <strong>${translateServer.common(accountPrivate.communicationLocale).Scheduled}:</strong>
                  ${dateFormatters.mm_dd_yyyy(moment(p.orgInvoice.autoChargeDateMS).toDate(), accountPrivate.communicationLocale)}
                </div>`
              : ""
          }

          ${`<p>${translateServer({
            defaultMessage: "You can view the all payment details in the Ollie app on the Tools tab.",
            serverLocale: accountPrivate.communicationLocale
          })}</p>`}</div>`;

          if (account) {
            ret.emailData = {
              type: "org",
              orgEmailPersonalization: {
                name: account.firstName,
                email: account.email,
                subject: emailSubject,
                message: emailBody
              },
              orgId: data.orgId,
              orgEmailReplyToEmailAddress: orgSettings?.registrationSettings?.defaultReplyToEmailAddress
              // orgEmailReplyToName: NATE TODO
            };
          }
          olliePipe.emitEvent({
            type: "metric-send-payment-notifications",
            payload: { type: "single-data-new-installment", ret }
          });

          return ret;
        }
      });
    } catch (e) {
      olliePipe.emitEvent({
        type: "metric-send-payment-notifications",
        payload: { type: "error-send-successful-parent", accountIdsToNotify }
      });
    }
  } else {
    const accountIdsToNotify = accountIdsToNotifyByPlayerBundleId[p.orgPayment.playerBundleId];
    olliePipe.emitEvent({
      type: "metric-send-payment-notifications",
      payload: { type: "send-successful-parent", accountIdsToNotify }
    });
    const prettyPlayerBundle = await playerBundle__server__getPrettyPlayerBundle({
      id: p.orgInvoiceChild.playerBundleId
    });
    try {
      await generateAndProcessNotifications({
        data: accountIdsToNotify.map(accountId => {
          return {
            accountId,
            orgPayment: p.orgPayment,
            orgId: p.orgPayment.orgId,
            orgInvoiceChild: p.orgInvoiceChild,
            fetchAccount: true
          };
        }),
        generateFn: async ({ accountPrivate, data, org, account, orgSettings }) => {
          if (!org) {
            return null;
          }

          const ret: NotificationGeneratorInfo__LowPriority = {
            type: "lowPriorityNotification",
            notificationType: NotificationType.lowPriorityNotification,
            idempotencyKey: ["successful-parent-org-invoice-payment", data.orgPayment.id].join("|")
          };

          let emailSubject = `${org.name} - `;
          let emailBody = ``;
          emailSubject =
            emailSubject +
            `${translateServer({ defaultMessage: "Payment Confimation", serverLocale: accountPrivate.communicationLocale })}`;
          emailBody = `<div>${`<p>${translateServer(
            {
              defaultMessage: "We have received your {amount} payment.",
              serverLocale: accountPrivate.communicationLocale
            },
            { amount: `${formatMoneyCentsToDollarCentPrettyString(p.orgPayment.amountCents)}` }
          )}</p>`}${`<p>${translateServer({
            defaultMessage: "You can view the all payment details in the Ollie app on the Tools tab.",
            serverLocale: accountPrivate.communicationLocale
          })}</p>`}</div>`;

          if (account) {
            ret.emailData = {
              type: "org",
              orgEmailPersonalization: {
                name: account.firstName,
                email: account.email,
                subject: emailSubject,
                message: emailBody
              },
              orgId: data.orgId,
              orgEmailReplyToEmailAddress: orgSettings?.registrationSettings?.defaultReplyToEmailAddress
              // orgEmailReplyToName: NATE TODO
            };
          }
          olliePipe.emitEvent({
            type: "metric-send-payment-notifications",
            payload: { type: "single-data-successful-parent", ret }
          });

          return ret;
        }
      });
    } catch (e) {
      olliePipe.emitEvent({
        type: "metric-send-payment-notifications",
        payload: { type: "error-send-successful-parent", accountIdsToNotify }
      });
    }
  }

  // SERVER_ONLY_TOGGLE
}
