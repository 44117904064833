import {
  RealTimeNotification,
  ActionRequest,
  LowPriorityNotificationDetail,
  NotificationType,
  ActionRequestId,
  LowPriorityNotificationDetailId
} from "@ollie-sports/models";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";
import { combineBifrostSubscriptions } from "../../internal-utils/combineBifrostSubscriptions";
import { RTBDRefs } from "../../constants";
import { firestoreLiftDocsSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function notification__client__getNotificationDetailSubscription(p: {
  realtimeNotifications: RealTimeNotification[];
  selfAccountId: string;
}): BifrostSubscription<{ actionRequests: ActionRequest[]; lowPriorityNotifications: LowPriorityNotificationDetail[] }> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const actionNotificationIds: ActionRequestId[] = p.realtimeNotifications
    .filter(n => n.t === NotificationType.actionRequest)
    .map(an => {
      if (an.t === NotificationType.actionRequest) {
        return an.rId;
      }
      return "";
    });

  const lowPriorityNotificationIds: LowPriorityNotificationDetailId[] = p.realtimeNotifications
    .filter(n => n.t === NotificationType.lowPriorityNotification)
    .map(an => {
      if (an.t === NotificationType.lowPriorityNotification) {
        return an.lpId;
      }
      return "";
    });

  const lowPrioritySub = firestoreLiftDocsSubToBifrostSub(
    h.LowPriorityNotificationDetail.docsSubscription(lowPriorityNotificationIds)
  );

  const actionsSub = firestoreLiftDocsSubToBifrostSub(h.ActionRequest.docsSubscription(actionNotificationIds));
  const sub = combineBifrostSubscriptions({ actionRequests: actionsSub, lowPriorityNotifications: lowPrioritySub });

  let failsafeCleanupFired = false;
  sub.onData(({ actionRequests, lowPriorityNotifications }) => {
    if (failsafeCleanupFired) {
      return;
    }
    failsafeCleanupFired = true;

    p.realtimeNotifications.forEach(notif => {
      let shouldCleanup = false;
      if (notif.t === NotificationType.actionRequest) {
        const matchingActionReq = actionRequests.find(ar => notif.rId === ar.id);

        if (!matchingActionReq) {
          shouldCleanup = true;
        }
      } else if (notif.t === NotificationType.lowPriorityNotification) {
        const matchingNotif = lowPriorityNotifications.find(a => a.id === notif.lpId);
        if (!matchingNotif) {
          shouldCleanup = true;
        }
      }

      if (shouldCleanup) {
        RTBDRefs.getAccountSimpleNotificationDeepRef({ accountId: p.selfAccountId, notificationId: notif.id })
          .remove()
          .catch(e => {
            console.error("Failed to self heal from missing actionRequest: " + e);
          });
      }
    });
  });

  return sub;
}

// i18n certified - complete
