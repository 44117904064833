import { translate } from "@ollie-sports/i18n";
import {
  AccountId,
  CalendarEntryId,
  LowPriorityNotificationDetail,
  LowPriorityNotificationDetailType,
  NotificationBundle,
  NotificationType,
  PushNotificationSettingToRespect,
  SoccerGameId
} from "@ollie-sports/models";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import moment from "moment";
import { getUniversalHelpers } from "../../helpers";
import { generatePushID } from "../../internal-utils/firebaseId";
import { processNotificationBundles } from "./notification.plumbing";
import { fetchAccountPrivatesCached } from "../../utils/date-helpers";

export async function notification__server__triggerForCoachShareReminder(p: {
  accountId: AccountId;
  calendarEntryId: CalendarEntryId;
  soccerGameId: SoccerGameId;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const accountPrivate = (await fetchAccountPrivatesCached({ accountIds: [p.accountId] }))[0];
  if (!accountPrivate) {
    olliePipe.emitEvent({ type: "error-unable-to-find-accountPrivate", payload: p.accountId });
    return { success: false };
  }

  const body = translate({
    defaultMessage: `Click here to share the game stats with the opposing coach.`,
    serverLocale: accountPrivate?.communicationLocale ?? "en-us"
  });
  const title = translate({
    defaultMessage: "Share Stats With Opposing Coach!",
    serverLocale: accountPrivate?.communicationLocale ?? "en-us"
  });
  const triggerEventId = "coach-share-reminder-" + p.calendarEntryId;
  const now = Date.now();

  const lp: LowPriorityNotificationDetail = {
    body: body,
    title: title,
    createdAtMS: now,
    expireAtMS: moment().add(30, "days").valueOf(),
    id: h.LowPriorityNotificationDetail.generateId(),
    routerPath: `main/stats/${p.calendarEntryId}/OpenShare`,
    type: LowPriorityNotificationDetailType.coachShareReminder
  };

  await h.LowPriorityNotificationDetail.add({
    doc: lp
  });

  const pushId = generatePushID();
  const notificationBundles: NotificationBundle[] = [
    {
      type: NotificationType.lowPriorityNotification,
      id: pushId,
      accountId: p.accountId,
      triggerEventId,
      pushNotificationData: {
        body: body,
        title: title,
        id: pushId,
        pushNotificationSettingToRespect: PushNotificationSettingToRespect.statsAndGame,
        triggerEventId,
        type: NotificationType.lowPriorityNotification,
        routerPath: lp.routerPath,
        lowPriorityNotificationDetailType: lp.type,
        soccerGameId: p.soccerGameId
      }
    }
  ];
  await processNotificationBundles({
    notificationBundles
  });

  return { success: true };
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForCoachShareReminder.auth = () => {};
// i18n certified - complete
