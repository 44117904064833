import { getUniversalHelpers } from "../../helpers";
import { validateSelfAccountId, validateUserIdentity } from "../../internal-utils/server-auth";
import { sendNotificationsForNewMessage } from "./message-helpers";
export async function notification__server__triggerForMessage(p: { selfAccountId: string; messageId: string }) {
  await sendNotificationsForNewMessage(p);
}

notification__server__triggerForMessage.auth = async (r: any) => {
  await validateUserIdentity({ request: r, propertyToCheck: "selfAccountId" });
};

// i18n certified - complete
