import {
  CONVERSATION_TYPES,
  NotificationBundle,
  NotificationType,
  AccountPrivate,
  NotificationEventTriggerResult,
  NotificationSettings,
  PushNotificationData,
  DeviceRegistration,
  NotificationChannels,
  RealTimeNotification,
  ExcludeNotificationTypesFromBadgeCount,
  PushNotificationSettingToRespect,
  LowPriorityNotificationDetail,
  NotificationBundle_LowPriority,
  PushNotificationData_LowPriority,
  RealTimeNotification_LowPriority,
  AccountId,
  OrgId,
  Org,
  OrgSettings,
  Account
} from "@ollie-sports/models";
import moment from "moment";
import { queue } from "async";
import { getUniversalHelpers, getServerHelpers } from "../../helpers";
import _ from "lodash";
import {
  getSimpleNotificationRef,
  getDeviceRegistrationRefV2,
  getAllDeviceRegistrationsRefV2
} from "../../internal-utils/realtime-database-ref";
import { messaging } from "firebase-admin";
import { fetchAccountPrivatesCached, fetchAccountsCached } from "../../utils/date-helpers";
import objectHash from "object-hash";
import { generatePushID } from "../../internal-utils/firebaseId";
import { OrgEmailRequiredPersonalizations, isProduction, sendOrgEmail } from "../../utils";

interface PendingPushNotification {
  accountId: string;
  deviceId: string;
  badgeCount: number;
  token: string;
  data: PushNotificationData;
  playSound: boolean;
}

export async function processNotificationBundles(p: { notificationBundles: NotificationBundle[] }) {
  const notificationBundles = p.notificationBundles.map(a => ({
    ...a,
    //Replace firebase RTDB disallowed characters...
    triggerEventId: a.triggerEventId.replace(/[.$#[\]]/g, b => b.charCodeAt(0).toString())
  }));

  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const dateMask = moment().format("YYYY-MM-DD");
  const hasRunRootRef = getUniversalHelpers().app.database().ref(`notificationTriggerEventHasRun/${dateMask}`);

  // Basically in all cases there will be just one triggerEventId
  const uniqueTriggerEvents = _.uniq(notificationBundles.map(b => b.triggerEventId));
  for (let i = 0; i < uniqueTriggerEvents.length; i++) {
    let result: NotificationEventTriggerResult = {} as any;
    const pendingPushNotifications: PendingPushNotification[] = [];
    try {
      const triggerEventId = uniqueTriggerEvents[i];

      // *********************
      // Little safety mechanism to make sure we haven't already triggered notifications for this triggerEventId
      // Meant to prevent spamming if endpoints are accidentally called multiple times
      // *********************
      const hasRunRef = hasRunRootRef.child(triggerEventId);
      const hasRunSnap = await hasRunRef.once("value");
      if (hasRunSnap.val()) {
        olliePipe.emitEvent({ type: "error-trigger-event-id-already-run", payload: triggerEventId });
        continue;
      }
      // Slight race condition here. Likely a better way but better than nothing
      await hasRunRef.set(true);

      const bundles = notificationBundles.filter(b => b.triggerEventId === triggerEventId);

      // Add this initial processing event for audit
      olliePipe.emitEvent({
        type: "metric-notification-bundle-processing-init",
        payload: {
          triggerEventId,
          accountIds: bundles.reduce((acc: { [accountId: string]: true }, val) => {
            acc[val.accountId] = true;
            return acc;
          }, {})
        }
      });

      // Build notification trigger result to track progress
      result = {
        status: "init",
        bundles: bundles.reduce((acc: NotificationEventTriggerResult["bundles"], bundle) => {
          acc[bundle.accountId] = { bundle };
          return acc;
        }, {}),
        createdAtMS: Date.now(),
        totalPushNotificationsSent: 0,
        totalRealTimeNotificationsSent: 0,
        totalPushNotificationErrors: 0,
        totalUnexpectedErrors: 0,
        triggerEventId
      };

      // *********************
      // Primary notification bundle handle fn
      // *********************
      const handleEnqueuedNotificationBundle = async (bundle: NotificationBundle) => {
        try {
          let accountPrivate = (await fetchAccountPrivatesCached({ accountIds: [bundle.accountId] }))[0];
          // let accountPrivate = await h.AccountPrivate.getDoc(bundle.accountId);

          if (!accountPrivate) {
            result.bundles[bundle.accountId].unexpectedBundleError = "Account private not found!";
            result.totalUnexpectedErrors += 1;
            return;
          }

          // Send realtime notification
          let realTimeSent: boolean;
          const notif = bundle.realTimeNotification;
          if (notif) {
            // Messages are special unicorns because they can be muted, which disables push and realtime notifications
            if (bundle.type === NotificationType.chatMessage) {
              const isConvoMuted = accountPrivate.settings?.conversations?.[bundle.pushNotificationData.conversationId]?.muted;
              const isSendingAccountMutedByReceivingAccount =
                !!accountPrivate.settings?.conversations?.[bundle.pushNotificationData.conversationId]?.mutedAccountIds?.[
                  bundle.pushNotificationData.sendingAccountId
                ];
              if (isConvoMuted || isSendingAccountMutedByReceivingAccount) {
                result.bundles[bundle.accountId].pushResult = { sent: false, notes: `Conversation muted` };
                return; // Return early
              }
            }
            await getSimpleNotificationRef({ accountId: bundle.accountId }).child(bundle.id).set(bundle.realTimeNotification);
            realTimeSent = true;
          } else {
            realTimeSent = false;
          }

          result.bundles[bundle.accountId].realtimeResult = { sent: realTimeSent };

          // Send push notification
          if (bundle.pushNotificationData) {
            const pushNotificationSettingToRespect = bundle.pushNotificationData.pushNotificationSettingToRespect;
            // Check if notifications are disabled based on user settings

            if (
              pushNotificationSettingToRespect !== PushNotificationSettingToRespect.ALWAYS_SEND &&
              !accountPrivate.settings?.notifications?.[pushNotificationSettingToRespect as unknown as NotificationSettings]
            ) {
              result.bundles[bundle.accountId].pushResult = { sent: false, notes: `Disabled by notification settings` };
              return; // Return early
            }

            const pushTokenDetailsResult = await determinePushTokenDetails({ accountId: bundle.accountId });

            const pushTokenDetails = _.compact(pushTokenDetailsResult ?? []);

            if (!pushTokenDetails.length) {
              result.bundles[bundle.accountId].pushResult = { sent: false, notes: `No push token found` };
              return; // Return early
            }

            for (let j = 0; j < pushTokenDetails.length; j++) {
              const tokenDetails = pushTokenDetails[j];
              const badgeCount = tokenDetails.os === "ios" ? await determineBadgeCount({ accountId: bundle.accountId }) : 0;

              // Queue push
              pendingPushNotifications.push({
                accountId: bundle.accountId,
                data: bundle.pushNotificationData,
                badgeCount,
                deviceId: tokenDetails.deviceId,
                playSound: true,
                token: tokenDetails.fcmToken
              });
            }
          }
        } catch (e) {
          getUniversalHelpers().olliePipe.emitEvent({
            type: "error-unexpected-error-processing-notification-bundle",
            payload: { errorMsg: String(e), bundle }
          });
          result.bundles[bundle.accountId].unexpectedBundleError = String(e);
          result.totalUnexpectedErrors += 1;
        }
      };

      const q = queue(handleEnqueuedNotificationBundle, 20);
      q.push(bundles);
      await q.drain();
      result.status = "realtime-sent";

      await sendPushNotifications({ currentEventTriggerResultRef: result, pendingPushNotifications: pendingPushNotifications });
      result.status = "finished";
    } catch (e) {
      olliePipe.emitEvent({ type: `error-processing-notification-bundles`, payload: e });
      result.unexpectedError = String(e);
      result.totalUnexpectedErrors += 1;
      result.status = "unexpected-error";
    } finally {
      await olliePipe.emitEvent(
        { type: "metric-notification-bundle-processing-finished", payload: result },
        { sendImmediate: true }
      );
    }
  }
  // SERVER_ONLY_TOGGLE
}

async function sendPushNotifications(p: {
  pendingPushNotifications: PendingPushNotification[];
  currentEventTriggerResultRef: NotificationEventTriggerResult;
}) {
  // SERVER_ONLY_TOGGLE
  const { appFirebaseAdminApp } = getServerHelpers();

  const normalPushNotifications = p.pendingPushNotifications;
  let chunks = _.chunk(normalPushNotifications, 250);
  for (let i = 0; i < chunks.length; i++) {
    const pushNotification = chunks[i];
    const messages: messaging.Message[] = [];
    pushNotification.forEach((b, index) => {
      messages[index] = {
        notification: { body: b.data.body, title: b.data.title },
        data: b.data as any,
        apns: {
          headers: {
            "apns-priority": "10"
          },
          payload: {
            aps: {
              sound: b.playSound ? "default" : undefined,
              badge: b.badgeCount
            }
          }
        },
        android: {
          priority: "high",
          notification: {
            icon: "notification_icon",
            channelId: NotificationChannels.ollieNormal,
            priority: "max"
          }
        },
        token: b.token
      };
    });

    const chunkResponse = await appFirebaseAdminApp.messaging().sendEach(messages);

    p.currentEventTriggerResultRef.totalPushNotificationErrors += chunkResponse.failureCount;
    p.currentEventTriggerResultRef.totalPushNotificationsSent += chunkResponse.successCount;

    let test: NotificationEventTriggerResult["bundles"]["key"]["pushResult"][] = [];
    for (let k = 0; k < chunkResponse.responses.length; k++) {
      const response = chunkResponse.responses[k];
      const originalBundle = chunks[i][k];
      const pushResultAudit: NotificationEventTriggerResult["bundles"]["key"]["pushResult"] = {
        sent: response.success,
        deviceId: originalBundle.deviceId,
        tokenShort: originalBundle.token.substr(0, 10)
      };
      if (response.error) {
        // Haven't decided how to handle these two errors yet. Infrequent enough maybe just ignore.
        // messaging/third-party-auth-error
        // messaging/internal-error
        // messaging/mismatched-credential
        // messaging/invalid-argument

        const errorCodesThatTriggerTokenRemoval = ["messaging/registration-token-not-registered"];

        let tokenRemoved = false;

        if (errorCodesThatTriggerTokenRemoval.includes(response.error.code)) {
          try {
            const userDeviceRegistrationRef = getServerHelpers()
              .appFirebaseAdminApp.database()
              .ref(`deviceRegistration/${originalBundle.accountId}/${originalBundle.deviceId}`);
            await userDeviceRegistrationRef.child("token").remove();
            await userDeviceRegistrationRef.child("derived").remove();
            tokenRemoved = true;
          } catch (e) {
            getUniversalHelpers().olliePipe.emitEvent({
              type: "error-trouble-removing-token-from-system",
              payload: e
            });
          }
        }

        pushResultAudit.errorDetails = {
          code: response.error.code,
          msg: `${tokenRemoved ? "Token has been removed from system. Msg:" : ""}${response.error.message}`,
          stack: response.error.stack
        };
      } else if (response.messageId) {
        pushResultAudit.fcmMsgId = response.messageId;
      } else {
        getUniversalHelpers().olliePipe.emitEvent({
          type: "error-unexpected-fcm-response-state",
          payload: { msg: "No error but also no messageId. Unexpected.", response, originalBundle }
        });
        p.currentEventTriggerResultRef.totalUnexpectedErrors += 1;
      }
      p.currentEventTriggerResultRef.bundles[originalBundle.accountId].pushResult = pushResultAudit;
      test.push(pushResultAudit);
    }
  }
  // SERVER_ONLY_TOGGLE
}

async function determineBadgeCount(p: { accountId: string }): Promise<number> {
  // SERVER_ONLY_TOGGLE
  const ref = getSimpleNotificationRef(p);
  const r1 = await ref.once("value");
  const realtimeNotifications: { [key: string]: RealTimeNotification } | undefined = r1.val();
  const now = Date.now();

  let count = 0;
  if (realtimeNotifications) {
    Object.keys(realtimeNotifications).forEach(id => {
      const noti = realtimeNotifications[id];
      if (noti.e < now) {
        // TODO: Move this to a cron job. For now this is better than nothing though
        // Clean expired notifications
        ref.child(noti.id).remove();
        return;
      }
      if (ExcludeNotificationTypesFromBadgeCount.includes(noti.t)) {
        return;
      }
      count++;
    });
  }

  return count;
  // SERVER_ONLY_TOGGLE
}

export async function deleteDeviceRegistrationV2Ref(p: { accountId: AccountId; deviceId: string }) {
  const deviceRef = getDeviceRegistrationRefV2(p);
  await deviceRef.remove();
}

async function determinePushTokenDetails(p: { accountId: string }): Promise<DeviceRegistration["derived"][] | void> {
  // SERVER_ONLY_TOGGLE
  const allDeriveds: Record<string, DeviceRegistration["derived"]> = {};
  const usedDeviceNameYearClassCombosWithCreatedAtMS: Record<
    string, // deviceName + deviceYearClass
    {
      deviceId: string;
      createdAtMS: number;
    }
  > = {};

  const allDeviceRegistrations: DeviceRegistration[] = [];

  let deviceRegistration: Record<string, DeviceRegistration> | undefined = (
    await getAllDeviceRegistrationsRefV2({ accountId: p.accountId }).once("value")
  ).val();

  if (deviceRegistration) {
    Object.values(deviceRegistration).forEach(d => {
      allDeviceRegistrations.push(d);
    });
  }

  const allDeviceRegistrationsSorted = _.orderBy(allDeviceRegistrations, a => a.createdAtMS, "asc");
  for (let i = 0; i < allDeviceRegistrationsSorted.length; i++) {
    const device = allDeviceRegistrationsSorted[i];
    if (device.derived) {
      const deviceNameYearCombo = device.platform?.ios?.platform ?? device.deviceName + device.deviceYearClass;
      const usedDetails = usedDeviceNameYearClassCombosWithCreatedAtMS[deviceNameYearCombo];

      let shouldUseThisDevice = false;

      if (usedDetails) {
        if (device.createdAtMS > usedDetails.createdAtMS) {
          const oldDeviceId = usedDeviceNameYearClassCombosWithCreatedAtMS[deviceNameYearCombo].deviceId;
          delete allDeriveds[oldDeviceId];

          await deleteDeviceRegistrationV2Ref({ accountId: p.accountId, deviceId: oldDeviceId });

          shouldUseThisDevice = true;
        } else {
          // Do nothing since this is an old version of the same device we have already used
        }
      } else {
        shouldUseThisDevice = true;
      }

      if (shouldUseThisDevice) {
        usedDeviceNameYearClassCombosWithCreatedAtMS[deviceNameYearCombo] = {
          createdAtMS: device.createdAtMS,
          deviceId: device.deviceId
        };
        allDeriveds[device.derived.deviceId] = device.derived;
      }
    }
  }

  const derivedValuesWithFCMTokens = Object.values(allDeriveds).filter(a => !!a?.fcmToken);

  if (derivedValuesWithFCMTokens) {
    return derivedValuesWithFCMTokens;
  } else {
    throw new Error("Found no FCM token");
  }
  // SERVER_ONLY_TOGGLE
}

export type EmailDataNormal = {
  type: "normal";
  email: string;
  templateId: string;
  templateData?: Record<string, string | undefined>;
  unsubscribeGroup?: number;
  hideWarnings?: boolean;
};

export type EmailDataOrg = {
  type: "org";
  orgId: OrgId;
  orgEmailPersonalization: OrgEmailRequiredPersonalizations;
  orgEmailReplyToEmailAddress?: string;
  orgEmailReplyToName?: string;
};

export type NotificationGeneratorInfo__LowPriority = {
  type: "lowPriorityNotification";
  notificationType: NotificationType;
  idempotencyKey: string; //Prevents double sends
  lowPriorityNotificationDetail?: Omit<LowPriorityNotificationDetail, "id" | "createdAtMS">; //Need to add the locale since a LowPriorityNotificationDetail will be created per locale
  pushNotificationData?: Omit<PushNotificationData_LowPriority, "id" | "triggerEventId" | "type">;
  realTimeNotification?: Omit<RealTimeNotification_LowPriority, "d" | "id" | "lpId" | "t">;
  emailData?: EmailDataNormal | EmailDataOrg;
};

//TODO: This is a helper that can be called instead of the more low level processNotificationBundles
//It should typically be preferred when generating low priority notifications
export async function generateAndProcessNotifications<T extends { accountId: string; orgId?: OrgId; fetchAccount?: boolean }>(p: {
  data: T[];
  generateFn: (d: {
    data: T;
    accountPrivate: AccountPrivate;
    account?: Account;
    org?: Org;
    orgSettings?: OrgSettings;
  }) => Promise<null | NotificationGeneratorInfo__LowPriority>;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const { emailTemplateServiceFn } = getServerHelpers();
  const nowMS = Date.now();

  const orgIds = _(p.data.map(a => a.orgId))
    .compact()
    .uniq()
    .value();

  const [accountPrivates, orgData, orgSettingsData, accounts] = await Promise.all([
    fetchAccountPrivatesCached({ accountIds: p.data.map(a => a.accountId) }),
    orgIds.length ? h.Org.getDocs(orgIds) : undefined,
    orgIds.length ? h.OrgSettings.getDocs(orgIds) : undefined,
    fetchAccountsCached({
      accountIds: _.compact(
        p.data.map(a => {
          if (a.fetchAccount) {
            return a.accountId;
          }
          return null;
        })
      )
    })
  ]);

  const orgs = _.compact(orgData ?? []);
  const orgSettings = _.compact(orgSettingsData ?? []);

  const allInfo = await Promise.all(
    p.data.map(async d => {
      const accountPrivate = accountPrivates.find(a => a?.id === d.accountId);

      if (!accountPrivate) {
        return null;
      }

      try {
        const info = await p.generateFn({
          data: d,
          accountPrivate,
          account: d.fetchAccount ? accounts.find(acc => acc.id === d.accountId) : undefined,
          org: d.orgId ? orgs.find(o => o.id === d.orgId) : undefined,
          orgSettings: d.orgId ? orgSettings.find(o => o.id === d.orgId) : undefined
        });
        if (!info) {
          return null;
        }

        return { accountPrivate, info };
      } catch (e) {
        olliePipe.emitEvent({ type: "error-generating-notification", payload: e });
        return null;
      }
    })
  ).then(a => _.compact(a));

  const allInfoWithMeta = allInfo
    .filter((a): a is { accountPrivate: AccountPrivate; info: NotificationGeneratorInfo__LowPriority } => !!a.info)
    .map(a => {
      return {
        lowPriorityDetailHash: a.info.lowPriorityNotificationDetail
          ? a.accountPrivate.communicationLocale + objectHash(a.info.lowPriorityNotificationDetail)
          : null,
        ...a
      };
    });

  const lowPriorityNotificationDetailsToAddByHash: Record<string, LowPriorityNotificationDetail> = {};
  _(allInfoWithMeta)
    .uniqBy(a => a.lowPriorityDetailHash)
    .forEach(a => {
      if (a.info.type !== "lowPriorityNotification") {
        return;
      }

      const { ...lp } = a.info.lowPriorityNotificationDetail!;

      lowPriorityNotificationDetailsToAddByHash[a.lowPriorityDetailHash!] = {
        ...lp,
        createdAtMS: nowMS,
        id: h.LowPriorityNotificationDetail.generateId()
      };
    });

  const lowPriorityDetailsBatchTasks = await Promise.all(
    Object.values(lowPriorityNotificationDetailsToAddByHash).map(a =>
      h.LowPriorityNotificationDetail.add({ doc: a }, { returnBatchTask: true })
    )
  );

  await Promise.all(
    _.chunk(lowPriorityDetailsBatchTasks, 300).map(async chunk => {
      return await h._BatchRunner.executeBatch(chunk);
    })
  );

  try {
    const notificationBundles = _(allInfoWithMeta)
      .filter(a => !!a.info.pushNotificationData)
      .map(a => {
        const notificationId = generatePushID();

        const notificationBundle: NotificationBundle = {
          accountId: a.accountPrivate.id,
          id: notificationId,
          triggerEventId: a.info.idempotencyKey,
          type: a.info.notificationType as any
        };

        const lp = a.lowPriorityDetailHash && lowPriorityNotificationDetailsToAddByHash[a.lowPriorityDetailHash];

        if (a.info.realTimeNotification && lp) {
          notificationBundle.realTimeNotification = {
            ...a.info.realTimeNotification,
            t: a.info.notificationType as any,
            lpId: lp.id,
            id: notificationId,
            d: nowMS
          };
        }

        if (a.info.pushNotificationData) {
          notificationBundle.pushNotificationData = {
            ...a.info.pushNotificationData,
            type: a.info.notificationType as any,
            triggerEventId: a.info.idempotencyKey,
            id: notificationId
          };
        }

        return notificationBundle;
      })
      .compact()
      .value();

    await processNotificationBundles({ notificationBundles });

    await Promise.all(
      allInfoWithMeta
        .filter(a => a.info.emailData?.type === "normal")
        .map(a => {
          if (a.info.emailData?.type === "normal") {
            const { email, templateData, templateId, unsubscribeGroup, hideWarnings } = a.info.emailData!;
            return emailTemplateServiceFn({
              to: email,
              templateData,
              templateId,
              asm: unsubscribeGroup,
              hideWarnings
            }).catch(e => {
              olliePipe.emitEvent({ type: "error-sending-email", payload: e });
            });
          }
          return null;
        })
    );

    const orgEmailsGroupedByOrgInfo = _.groupBy(
      allInfoWithMeta.filter(a => a.info.emailData?.type === "org"),
      b =>
        b.info.emailData?.type === "org"
          ? b.info.emailData.orgId + b.info.emailData.orgEmailReplyToEmailAddress + b.info.emailData.orgEmailReplyToName
          : ""
    );
    await Promise.all(
      Object.keys(orgEmailsGroupedByOrgInfo).map(orgIdHash => {
        const group = orgEmailsGroupedByOrgInfo[orgIdHash];
        const orgId = group[0].info.emailData?.type === "org" ? group[0].info.emailData.orgId : undefined;
        if (group.length && orgId) {
          return sendOrgEmail({
            orgId,
            personalizations: _.compact(
              group.map(a => (a.info.emailData?.type === "org" ? a.info.emailData.orgEmailPersonalization : null))
            ),
            replyToEmailAddress:
              group[0].info.emailData?.type === "org" ? group[0].info.emailData.orgEmailReplyToEmailAddress : undefined,
            replyToName: group[0].info.emailData?.type === "org" ? group[0].info.emailData.orgEmailReplyToName : undefined
          });
        } else {
          return null;
        }
      })
    );
  } catch (e) {
    olliePipe.emitEvent({ type: "error-sending-notifications", payload: e });
  }

  // SERVER_ONLY_TOGGLE
}

// i18n certified - complete
