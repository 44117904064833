import {
  SoccerGame,
  Team,
  NotificationBundle,
  NotificationType,
  PushNotificationSettingToRespect,
  LowPriorityNotificationDetail,
  LowPriorityNotificationDetailType,
  CalendarEntry,
  CalendarEntryGameScrimmage,
  TeamFeatures,
  EvaluationId,
  PlayerBundleId,
  PlayerBundle__AccountType,
  AccountId
} from "@ollie-sports/models";
import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { generatePushID } from "../../internal-utils/firebaseId";
import moment from "moment";
import { processNotificationBundles } from "./notification.plumbing";
import { getUniversalHelpers } from "../../helpers";
import { validateSelfAccountId, validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { timeHasPassedSinceScheduledGameStart } from "../../compute";
import { playerBundle__server__getPrettyPlayerBundle } from "../playerBundle";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { translate } from "@ollie-sports/i18n";
import _ from "lodash";
import { fetchAccountPrivatesCached } from "../../utils/date-helpers";
import { ObjectKeys, ObjectValues } from "../../utils";
import { player__server__getPrettyPlayer } from "../player";

export async function notification__server__triggerForEvaluation(p: {
  evaluationId: EvaluationId;
  playerBundleId?: PlayerBundleId;
  playerFirstName: string;
  playerLastName: string;
  accountIdsToNotify: { accountId: AccountId; type: "managingAccount" | "coach" | "orgAdmin" }[];
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const now = Date.now();

  const evaluation = await h.Evaluation.getDoc(p.evaluationId);

  if (!evaluation) {
    throw new Error("Invalid ids supplied to notification__server__triggerForEvaluation");
  }

  if (!evaluation.sentAtMS) {
    throw new Error("Can't send notification for evaluation that isn't complete in notification__server__triggerForEvaluation");
  }

  const prettyPlayer =
    evaluation.type === "player"
      ? await player__server__getPrettyPlayer({
          playerId: evaluation.playerId
        })
      : undefined;

  const evaluatorAccount = await h.Account.getDoc(evaluation.evaluatorId);

  const accountPrivates = _.compact(await fetchAccountPrivatesCached({ accountIds: p.accountIdsToNotify.map(a => a.accountId) }));

  const triggerEventId = evaluation.id + Date.now();
  const types: ("managingAccount" | "coach" | "orgAdmin")[] = _.uniq(p.accountIdsToNotify.map(a => a.type)).filter(
    a => a !== "managingAccount"
  );
  const expireAtMS = moment().add(30, "days").valueOf();

  const communicationLocales = _.uniq(accountPrivates.map(ap => ap.communicationLocale));
  const lpNotificationsByLocaleThenByType: Record<
    string,
    Record<string, LowPriorityNotificationDetail>
  > = communicationLocales.reduce((acc, locale) => {
    types.forEach(type => {
      if (type === "managingAccount") {
        return;
      }
      const lp: LowPriorityNotificationDetail = {
        body: translate(
          {
            defaultMessage: `{name} has completed a new player evaluation for {bundleName}.`,
            serverLocale: locale
          },
          {
            bundleName: `${p.playerFirstName} ${p.playerLastName}`,
            name: evaluatorAccount
              ? `${evaluatorAccount.firstName} ${evaluatorAccount.lastName}`
              : translate({ defaultMessage: "Someone", serverLocale: locale })
          }
        ),
        title: translate({ defaultMessage: `New Player Evaluation!`, serverLocale: locale }),
        id: h.LowPriorityNotificationDetail.generateId(),
        routerPath:
          type === "orgAdmin"
            ? `main/tools/orgSettings/evaluations/${evaluation.orgId ?? "noOrgId"}/${p.evaluationId}`
            : `main/teams/${evaluation.teamId}/noOrgId/noOpenAction/evaluations/${p.evaluationId}`,
        createdAtMS: now,
        type: LowPriorityNotificationDetailType.newEvaluation,
        expireAtMS
      };
      if (!acc[locale]) {
        acc[locale] = { [type]: lp };
      } else {
        acc[locale] = { ...acc[locale], ...{ [type]: lp } };
      }
    });
    return acc;
  }, {} as Record<string, Record<string, LowPriorityNotificationDetail>>);

  const allLowPriortyNotifications = _.flatten(Object.values(lpNotificationsByLocaleThenByType).map(a => Object.values(a)));

  await Promise.all(
    allLowPriortyNotifications.map(lp => {
      return h.LowPriorityNotificationDetail.add({ doc: lp });
    })
  );

  const notificationBundles: NotificationBundle[] = _.compact(
    p.accountIdsToNotify
      .map(a => ({ accountId: a.accountId, id: generatePushID(), type: a.type }))
      .map(a => {
        const accountPrivate = accountPrivates.find(acc => acc.id === a.accountId);
        const accountLocale = accountPrivate?.communicationLocale ?? "en-us";
        const title = translate({ defaultMessage: `New Player Evaluation!`, serverLocale: accountLocale });
        const athleteBody = translate(
          { defaultMessage: `{name} has completed a new player evaluation for you.`, serverLocale: accountLocale },
          {
            name: evaluatorAccount
              ? `${evaluatorAccount.firstName} ${evaluatorAccount.lastName}`
              : translate({ defaultMessage: "Someone", serverLocale: accountLocale })
          }
        );
        const nonAthleteBody = translate(
          {
            defaultMessage: `{name} has completed a new player evaluation for {bundleName}.`,
            serverLocale: accountLocale
          },
          {
            bundleName: p.playerFirstName,
            name: evaluatorAccount
              ? `${evaluatorAccount.firstName} ${evaluatorAccount.lastName}`
              : translate({ defaultMessage: "A coach", serverLocale: accountLocale })
          }
        );
        if (a.type === "managingAccount" && prettyPlayer && prettyPlayer?.derived.accountInfoSource !== "player") {
          return {
            type: NotificationType.evaluation,
            id: a.id,
            accountId: a.accountId,
            triggerEventId,
            pushNotificationData: {
              body:
                prettyPlayer.derived.playerBundle.managingAccounts?.[a.accountId]?.type === PlayerBundle__AccountType.selfAthlete
                  ? athleteBody
                  : nonAthleteBody,
              title: title,
              id: a.id,
              pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
              triggerEventId,
              type: NotificationType.evaluation,
              evaluationId: p.evaluationId,
              playerBundleId: prettyPlayer.derived.playerBundle.id
            },
            realTimeNotification: {
              d: now,
              id: a.id,
              t: NotificationType.evaluation,
              e: moment().add(30, "days").valueOf(),
              eId: p.evaluationId,
              pbId: prettyPlayer.derived.playerBundle.id
            }
          };
        } else {
          const communicationLocale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
          const lpnd = lpNotificationsByLocaleThenByType[communicationLocale][a.type];
          if (lpnd) {
            return {
              type: NotificationType.lowPriorityNotification,
              id: a.id,
              accountId: a.accountId,
              triggerEventId,
              pushNotificationData: {
                body: lpnd.body,
                title: lpnd.title,
                id: a.id,
                pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
                type: NotificationType.lowPriorityNotification,
                triggerEventId,
                routerPath: lpnd.routerPath,
                lowPriorityNotificationDetailType: lpnd.type
              },
              realTimeNotification: {
                d: now,
                e: expireAtMS,
                id: a.id,
                t: NotificationType.lowPriorityNotification,
                lpId: lpnd.id
              }
            };
          }
        }
        return null;
      })
  );

  await processNotificationBundles({
    notificationBundles
  });
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForEvaluation.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
