import {
  SoccerGame,
  Team,
  NotificationBundle,
  NotificationType,
  PushNotificationSettingToRespect,
  LowPriorityNotificationDetail,
  LowPriorityNotificationDetailType,
  CalendarEntry,
  CalendarEntryGameScrimmage,
  TeamFeatures
} from "@ollie-sports/models";
import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { generatePushID } from "../../internal-utils/firebaseId";
import moment from "moment";
import { processNotificationBundles } from "./notification.plumbing";
import { getUniversalHelpers } from "../../helpers";
import { validateSelfAccountId } from "../../internal-utils/server-auth";
import * as express from "express";
import { timeHasPassedSinceScheduledGameStart } from "../../compute";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import _ from "lodash";
import { fetchAccountPrivatesCached } from "../../utils/date-helpers";
import { translate } from "@ollie-sports/i18n";

export async function notification__server__triggerForGameStart(p: {
  calendarEntryId: string;
  teamId: string;
  soccerGameId: string;
  selfAccountId: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const now = Date.now();

  const [soccerGame, team, calendarEntry] = await Promise.all([
    h.SoccerGame.getDoc(p.soccerGameId),
    h.Team.getDoc(p.teamId),
    h.CalendarEntry.getDoc(p.calendarEntryId) as Promise<CalendarEntryGameScrimmage | null>
  ]);

  if (!soccerGame || !team || !calendarEntry) {
    throw new Error("Invalid ids supplied to notification__server__triggerForGameStart");
  }

  if (soccerGame.calendarEntryId !== calendarEntry.id || soccerGame.teamId !== p.teamId) {
    throw new Error("Relationships are not correct between fetched data in notification__server__triggerForGameStart");
  }

  if (p.selfAccountId !== soccerGame.recorderAccountId) {
    throw new Error("Cannot trigger start game notification if not the current stat recorder");
  }

  // Don't send notification if the game was scheduled in the past
  // Most likely watching film afterwards
  if (timeHasPassedSinceScheduledGameStart(calendarEntry, 4)) {
    return;
  }

  let accountIds = Object.keys(team.accounts);
  if (soccerGame.squad) {
    accountIds = await fetchAccountIdsOnSquad({ team: team, squad: soccerGame.squad, squadSubset: "all" });
  }

  const accountPrivates = await fetchAccountPrivatesCached({ accountIds });

  const triggerEventId = soccerGame.id + Date.now();
  const communicationLocales = _.uniq(accountPrivates.map(ap => ap.communicationLocale));

  const lpNotificationsByLocale: Record<string, LowPriorityNotificationDetail> = communicationLocales.reduce((acc, locale) => {
    const title = translate(
      { defaultMessage: `{name} vs. {opponentName} started!`, serverLocale: locale },
      { name: team.shortName, opponentName: calendarEntry.opponentName }
    );
    const body = translate({
      defaultMessage: "Join Live Game mode now to see key game events, add photos, and leave shoutouts for the players!",
      serverLocale: locale
    });
    const lp: LowPriorityNotificationDetail = {
      body: body,
      title: title,
      createdAtMS: now,
      expireAtMS: moment().add(30, "days").valueOf(),
      id: h.LowPriorityNotificationDetail.generateId(),
      routerPath: `liveGame/${calendarEntry.id}/timeline`,
      type: LowPriorityNotificationDetailType.gameStart
    };

    acc[locale] = lp;
    return acc;
  }, {} as Record<string, LowPriorityNotificationDetail>);

  await Promise.all(
    Object.values(lpNotificationsByLocale).map(lp => {
      return h.LowPriorityNotificationDetail.add({ doc: lp });
    })
  );

  const notificationBundles: NotificationBundle[] = _.compact(
    accountIds
      .filter(aid => aid !== p.selfAccountId)
      .map(accountId => ({ accountId, id: generatePushID() }))
      .map(a => {
        const communicationLocale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
        const lpnd = lpNotificationsByLocale[communicationLocale];
        if (lpnd) {
          return {
            type: NotificationType.lowPriorityNotification,
            id: a.id,
            accountId: a.accountId,
            triggerEventId,
            pushNotificationData: {
              body: lpnd.body,
              title: lpnd.title,
              id: a.id,
              pushNotificationSettingToRespect: PushNotificationSettingToRespect.statsAndGame,
              triggerEventId,
              type: NotificationType.lowPriorityNotification,
              routerPath: lpnd.routerPath,
              lowPriorityNotificationDetailType: lpnd.type,
              soccerGameId: soccerGame.id
            },
            realTimeNotification: {
              d: now,
              id: a.id,
              t: NotificationType.lowPriorityNotification,
              e: moment().add(30, "days").valueOf(),
              lpId: lpnd.id
            }
          };
        }
        return null;
      })
  );

  await processNotificationBundles({
    notificationBundles
  });
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForGameStart.auth = (req: express.Request) => {
  validateSelfAccountId(req, req.body.selfAccountId);
};

// i18n certified - complete
