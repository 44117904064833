import {
  AccountId,
  LowPriorityNotificationDetailType,
  NotificationType,
  OrgId,
  OrgInvoiceParent,
  OrgPaymentInvoiceCredit,
  OrgPaymentType,
  OrgSettings,
  PrettyPlayerBundle,
  PushNotificationSettingToRespect
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import moment from "moment-timezone";
import { dateFormatters, translate } from "@ollie-sports/i18n";
import {
  IndividualOrgInvoiceDetails,
  formatMoneyCentsToDollarCentPrettyString,
  getIndividualOrgInvoiceAmountDetails,
  ensureColorHasHashtag,
  updateRegistrationAndInvoicesLastKeyActionMS
} from "../../utils";
import { NotificationGeneratorInfo__LowPriority, generateAndProcessNotifications } from "./notification.plumbing";
import _ from "lodash";
import { playerBundle__server__getPrettyPlayerBundle } from "../playerBundle";
import { COLORS } from "../../constants";
import { getAccountIdsFromPlayerBundleIdForPaymentNotifications } from "./playerBundle-helpers";
import { getDefaultPaymentAccount } from "../../compute/account.compute";

type BaseProps = {
  type: "new" | "soonToBeDueReminder" | "pastDue";
  orgInvoices: OrgInvoiceParent[];
};

type NewProps = BaseProps & {
  type: "new";
};

type SoonToBeDueReminderProps = BaseProps & {
  type: "soonToBeDueReminder";
  numDays: number;
};

type PastDueProps = BaseProps & {
  type: "pastDue";
};

type Props = NewProps | SoonToBeDueReminderProps | PastDueProps;

export async function triggerForInvoiceNotifications(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  const playerBundleIds = p.orgInvoices.map(oi => oi.playerBundleId);
  const accountIdsToNotifyByPlayerBundleId = await getAccountIdsFromPlayerBundleIdForPaymentNotifications({ playerBundleIds });
  const today = moment().format("YYYY-MM-DD");
  olliePipe.emitEvent({
    type: "metric-send-invoice-notifications",
    payload: { type: "start-send", props: p }
  });

  const allAccountIds = _.flatMap(Object.values(accountIdsToNotifyByPlayerBundleId));

  try {
    await generateAndProcessNotifications({
      data: p.orgInvoices.reduce(
        (acc, val) => {
          const accountIdsToNotify = accountIdsToNotifyByPlayerBundleId[val.playerBundleId];
          accountIdsToNotify.forEach(accountId => {
            acc.push({
              accountId,
              orgId: val.orgId,
              fetchAccount: true,
              orgInvoice: val
            });
          });
          return acc;
        },
        [] as {
          accountId: AccountId;
          orgId: OrgId;
          fetchAccount: true;
          orgInvoice: OrgInvoiceParent;
        }[]
      ),
      generateFn: async ({ accountPrivate, data, org, account, orgSettings }) => {
        if (!org) {
          return null;
        }

        const timezone = org.timezone ?? "America/Denver";

        const [orgPaymentInvoiceCreditData, prettyPlayerBundle, accountSecret] = await Promise.all([
          h.OrgPayment.query({
            where: [{ type: ["==", OrgPaymentType.invoiceCredit] }, { invoiceId: ["==", data.orgInvoice.id] }]
          }),
          playerBundle__server__getPrettyPlayerBundle({ id: data.orgInvoice.playerBundleId }),
          h.AccountSecret.getDoc(accountPrivate.id)
        ]);

        if (!prettyPlayerBundle) {
          return null;
        }

        let orgPaymentInvoiceCredits = orgPaymentInvoiceCreditData.docs as OrgPaymentInvoiceCredit[];

        const defaultPaymentMethod = getDefaultPaymentAccount(Object.values(accountSecret?.paymentMethodsById ?? {}));

        if (!defaultPaymentMethod) {
          // Payment TODO
          return null;
        }

        const orgInvoiceDetails = getIndividualOrgInvoiceAmountDetails({
          orgInvoice: data.orgInvoice,
          orgPayments: orgPaymentInvoiceCredits,
          feeDetails: orgSettings?.customFeeDetails?.[defaultPaymentMethod.type],
          paymentMethodType: defaultPaymentMethod.type
        });

        const orgInvoiceCardString = getOrgInvoiceCardStringForEmail({
          locale: accountPrivate.communicationLocale,
          orgInvoice: data.orgInvoice,
          orgInvoiceDetails,
          orgSettings,
          prettyPlayerBundle,
          timezone
        });

        const ret: NotificationGeneratorInfo__LowPriority = {
          type: "lowPriorityNotification",
          notificationType: NotificationType.lowPriorityNotification,
          idempotencyKey: [p.type === "pastDue" ? "past-due-invoice" : "soonToBeDueReminder", data.orgInvoice.id, today].join("|")
        };

        const title =
          p.type === "new"
            ? `${org.shortName} - ${translate({
                defaultMessage: "New Invoice",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : p.type === "pastDue"
            ? `${org.shortName} - ${translate({
                defaultMessage: "Invoice Past Due",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : `${org.shortName} - ${translate({
                defaultMessage: "Invoice Due Reminder",
                serverLocale: accountPrivate.communicationLocale
              })}`;
        const body =
          p.type === "new"
            ? translate(
                {
                  defaultMessage:
                    "You have a new {amount} invoice for {playerName}. Click here to view the details and pay the invoice.",
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  amount: formatMoneyCentsToDollarCentPrettyString(orgInvoiceDetails.remainingAmount),
                  playerName: `${prettyPlayerBundle.derived.accountInfo.firstName} ${prettyPlayerBundle.derived.accountInfo.lastName}`
                }
              )
            : p.type === "pastDue"
            ? translate(
                {
                  defaultMessage:
                    "You have an outstanding invoice for {amount} that was due on {dueDate}. Click here to view the details and pay the invoice.",
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  amount: formatMoneyCentsToDollarCentPrettyString(orgInvoiceDetails.remainingAmount),
                  dueDate: dateFormatters.mm_dd_yyyy(
                    moment.tz(data.orgInvoice.dueDateMS, timezone),
                    accountPrivate.communicationLocale
                  )
                }
              )
            : p.numDays === 1
            ? translate(
                {
                  defaultMessage:
                    "You have an outstanding invoice for {amount} that is due tomorrow. Click here to view the details and pay the invoice.",
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  amount: formatMoneyCentsToDollarCentPrettyString(orgInvoiceDetails.remainingAmount)
                }
              )
            : translate(
                {
                  defaultMessage:
                    "You have an outstanding invoice for {amount} that is due on {dueDate}. Click here to view the details and pay the invoice.",
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  amount: formatMoneyCentsToDollarCentPrettyString(orgInvoiceDetails.remainingAmount),
                  dueDate: dateFormatters.mm_dd_yyyy(
                    moment.tz(data.orgInvoice.dueDateMS, timezone),
                    accountPrivate.communicationLocale
                  )
                }
              );
        const emailSubject =
          p.type === "new"
            ? `${org.name} - ${translate({
                defaultMessage: "New Invoice",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : p.type === "pastDue"
            ? `${org.name} - ${translate({
                defaultMessage: "Invoice Past Due",
                serverLocale: accountPrivate.communicationLocale
              })}`
            : `${org.name} - ${translate({
                defaultMessage: "Invoice Due Reminder",
                serverLocale: accountPrivate.communicationLocale
              })}`;
        const emailBody =
          p.type === "new"
            ? `${translate({
                defaultMessage: "You have a new invoice.",
                serverLocale: accountPrivate.communicationLocale
              })}${orgInvoiceCardString}`
            : p.type === "pastDue"
            ? `${translate(
                {
                  defaultMessage: "You have an outstanding invoice that was due on {dueDate}.",
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  dueDate: dateFormatters.mm_dd_yyyy(
                    moment.tz(data.orgInvoice.dueDateMS, timezone),
                    accountPrivate.communicationLocale
                  )
                }
              )}${orgInvoiceCardString}`
            : p.numDays === 1
            ? `${translate({
                defaultMessage: "You have an outstanding invoice that is due tomorrow.",
                serverLocale: accountPrivate.communicationLocale
              })}${orgInvoiceCardString}`
            : `${translate(
                {
                  defaultMessage: "You have an outstanding invoice is due on {dueDate}.",
                  serverLocale: accountPrivate.communicationLocale
                },
                {
                  dueDate: dateFormatters.mm_dd_yyyy(
                    moment.tz(data.orgInvoice.dueDateMS, timezone),
                    accountPrivate.communicationLocale
                  )
                }
              )}${orgInvoiceCardString}`;

        const routerPath = `main/tools/invoiceDetails/${data.orgInvoice.id}/${data.orgInvoice.playerBundleId}/${data.orgInvoice.orgId}`;

        const expireAtMS = moment().add(30, "days").valueOf();

        ret.lowPriorityNotificationDetail = {
          type:
            p.type === "pastDue"
              ? LowPriorityNotificationDetailType.manualInvoicePastDue
              : LowPriorityNotificationDetailType.manualInvoiceAlmostDueReminder,
          expireAtMS,
          routerPath,
          title,
          body
        };

        ret.pushNotificationData = {
          title,
          body,
          lowPriorityNotificationDetailType:
            p.type === "pastDue"
              ? LowPriorityNotificationDetailType.manualInvoicePastDue
              : LowPriorityNotificationDetailType.manualInvoiceAlmostDueReminder,
          pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
          routerPath
        };

        ret.realTimeNotification = {
          e: expireAtMS
        };

        if (account) {
          ret.emailData = {
            type: "org",
            orgEmailPersonalization: {
              name: account.firstName,
              email: account.email,
              subject: emailSubject,
              message: emailBody
            },
            orgId: data.orgId,
            orgEmailReplyToEmailAddress: orgSettings?.registrationSettings?.defaultReplyToEmailAddress
            // orgEmailReplyToName: NATE TODO
          };
        }
        olliePipe.emitEvent({
          type: "metric-send-invoice-notifications",
          payload: { type: "single-data", ret }
        });

        return ret;
      }
    });
    if (p.type === "new") {
      await updateRegistrationAndInvoicesLastKeyActionMS({
        accountIds: allAccountIds
      });
    }
  } catch (e) {
    olliePipe.emitEvent({
      type: "metric-send-invoice-notifications",
      payload: { type: "error-send", error: e }
    });
  }

  // SERVER_ONLY_TOGGLE
}

function getOrgInvoiceCardStringForEmail(p: {
  locale: string;
  orgInvoice: OrgInvoiceParent;
  prettyPlayerBundle: PrettyPlayerBundle;
  orgSettings?: OrgSettings;
  orgInvoiceDetails: IndividualOrgInvoiceDetails;
  timezone: string;
}) {
  const { serverConfig } = getServerHelpers();
  return `<div style='border: 2px solid #ccc; margin-bottom: 4px; padding: 20px; width: 400px; margin: auto; border-radius: 10px; font-family: Arial, sans-serif;'><div style='margin-bottom: 4px;'><strong>${
    translate.common(p.locale).ID
  }:</strong> ${p.orgInvoice.id}</div><div style='margin-bottom: 4px;'><strong>${translate.common(p.locale).Player}:</strong> ${
    p.prettyPlayerBundle.derived.accountInfo.firstName
  } ${p.prettyPlayerBundle.derived.accountInfo.lastName}</div><div style='margin-bottom: 4px;'><strong>${
    translate.common(p.locale).Memo
  }:</strong> ${p.orgInvoice.memo}</div><div style='margin-bottom: 4px;'><strong>${
    translate.common(p.locale).Amount
  }:</strong> ${formatMoneyCentsToDollarCentPrettyString(
    p.orgInvoiceDetails.remainingAmount
  )}</div><div style='margin-bottom: 4px;'><strong>${translate.common(p.locale).DueDate}:</strong> ${dateFormatters.mm_dd_yyyy(
    moment.tz(p.orgInvoice.dueDateMS, p.timezone),
    p.locale
  )}</div>${
    p.orgInvoice.lateFeeCentsToBeIssuedIfLate
      ? `<div><strong>${translate.common(p.locale).LateFee}:</strong> ${formatMoneyCentsToDollarCentPrettyString(
          p.orgInvoice.lateFeeCentsToBeIssuedIfLate
        )}</div>`
      : ""
  }<a style='margin-top: 30px;' href="${`${serverConfig.httpWebappRoot}/org/${p.orgInvoice.orgId}/payInvoice/${p.orgInvoice.id}`}" style="display: inline-block; padding: 10px 20px; color: white; background-color: ${
    p.orgSettings?.primaryColor ? ensureColorHasHashtag(p.orgSettings.primaryColor) : COLORS.green
  }; text-decoration: none; border-radius: 5px; font-size: 16px;">${translate({
    defaultMessage: "Pay Now",
    serverLocale: p.locale
  })}</a></div>`;
}

// i18n certified - complete
