import { AccountId, PlayerBundleId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import {
  extractGuardianAccountIdsFromPlayerBundle,
  extractSelfAccountIdFromPlayerBundle
} from "../../compute/playerBundle.compute";

export async function getAccountIdsFromPlayerBundleIdForPaymentNotifications(p: { playerBundleIds: PlayerBundleId[] }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const playerBundles = _.compact(await h.PlayerBundle.getDocs(_.uniq(p.playerBundleIds)));
  return playerBundles.reduce((acc, playerBundle) => {
    let guardianAccountIds = extractGuardianAccountIdsFromPlayerBundle({ bundle: playerBundle });
    let selfAthleteAccountIds = _.compact([extractSelfAccountIdFromPlayerBundle({ bundle: playerBundle })]);
    const accountIdsToNotify = guardianAccountIds.length ? guardianAccountIds : selfAthleteAccountIds;
    acc[playerBundle.id] = accountIdsToNotify;
    return acc;
  }, {} as Record<PlayerBundleId, AccountId[]>);
}
