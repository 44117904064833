import { translate } from "@ollie-sports/i18n";
import {
  AccountId,
  CalendarEntryGameScrimmage,
  CalendarEntryId,
  LowPriorityNotificationDetail,
  LowPriorityNotificationDetailType,
  NotificationBundle,
  NotificationType,
  PushNotificationSettingToRespect,
  SoccerGame
} from "@ollie-sports/models";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import _ from "lodash";
import moment from "moment";
import { timeHasPassedSinceScheduledGameStart } from "../../compute/calendarEntry.compute";
import { getUniversalHelpers } from "../../helpers";
import { generatePushID } from "../../internal-utils/firebaseId";
import { fetchAccountPrivatesCached, ObjectKeys } from "../../utils";
import { processNotificationBundles } from "./notification.plumbing";

export async function notification__server__triggerForVotingReminder(p: {
  soccerGame: SoccerGame;
  calendarEntryId: CalendarEntryId;
  accountIds: AccountId[];
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const calendarEntry = await h.CalendarEntry.getDoc(p.calendarEntryId);

  if (!calendarEntry) {
    throw new Error("Invalid calendar entry id supplied to notification__server__triggerForVotingReminder");
  }

  // Don't send notification if the game was scheduled in the past
  // Most likely watching film afterwards
  if (timeHasPassedSinceScheduledGameStart(calendarEntry as CalendarEntryGameScrimmage, 6)) {
    return;
  }

  const numberOfVotes = p.soccerGame.gameStage === "ended" ? ObjectKeys(p.soccerGame.votes ?? {}) : 0;

  const accountPrivates = await fetchAccountPrivatesCached({ accountIds: p.accountIds });

  const triggerEventId = "voting-reminder-" + p.soccerGame.id;
  const communicationLocales = _.uniq(accountPrivates.map(ap => ap.communicationLocale));

  const lpNotificationsByLocale: Record<string, LowPriorityNotificationDetail> = communicationLocales.reduce((acc, locale) => {
    const body = `${translate({ defaultMessage: "Show your support for the players by voting now", serverLocale: locale })}.${
      typeof numberOfVotes === "number" && numberOfVotes > 2
        ? translate(
            { defaultMessage: `{numberOfVotes} people have already voted.`, serverLocale: locale },
            { numberOfVotes: `${numberOfVotes}` }
          )
        : ``
    }`;
    const title = translate({ defaultMessage: "Voting Closes in 15 Minutes.", serverLocale: locale });
    const now = Date.now();

    const lp: LowPriorityNotificationDetail = {
      body: body,
      title: title,
      createdAtMS: now,
      expireAtMS: moment().add(30, "days").valueOf(),
      id: h.LowPriorityNotificationDetail.generateId(),
      routerPath: `main/stats/${p.calendarEntryId}/OpenVoting`,
      type: LowPriorityNotificationDetailType.votingReminder
    };

    acc[locale] = lp;
    return acc;
  }, {} as Record<string, LowPriorityNotificationDetail>);

  await Promise.all(
    Object.values(lpNotificationsByLocale).map(lp => {
      return h.LowPriorityNotificationDetail.add({ doc: lp });
    })
  );

  const notificationBundles: NotificationBundle[] = [];
  p.accountIds
    .map(accountId => ({ accountId, id: generatePushID() }))
    .forEach(a => {
      const communicationLocale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
      const lpnd = lpNotificationsByLocale[communicationLocale];
      if (lpnd) {
        notificationBundles.push({
          type: NotificationType.lowPriorityNotification,
          id: a.id,
          accountId: a.accountId,
          triggerEventId,
          pushNotificationData: {
            body: lpnd.body,
            title: lpnd.title,
            id: a.id,
            pushNotificationSettingToRespect: PushNotificationSettingToRespect.awards,
            triggerEventId,
            type: NotificationType.lowPriorityNotification,
            routerPath: lpnd.routerPath,
            lowPriorityNotificationDetailType: lpnd.type,
            soccerGameId: p.soccerGame.id
          }
        });
      }
    });
  await processNotificationBundles({
    notificationBundles
  });

  return { success: true };
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForVotingReminder.auth = () => {};
// i18n certified - complete
