import {
  NotificationBundle,
  NotificationType,
  PushNotificationSettingToRespect,
  LowPriorityNotificationDetail,
  LowPriorityNotificationDetailType,
  CalendarEntryGameScrimmage,
  Team__StaffTypes
} from "@ollie-sports/models";
import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { generatePushID } from "../../internal-utils/firebaseId";
import moment from "moment";
import { processNotificationBundles } from "./notification.plumbing";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import _ from "lodash";
import { fetchAccountPrivatesCached } from "../../utils/date-helpers";

export async function notification__server__triggerForLiveGameModePhotosPlea(
  this: ServerThisContext,
  p: {
    calendarEntryId: string;
    teamId: string;
    soccerGameId: string;
    selfAccountId: string;
  }
) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const now = Date.now();

  const [soccerGame, team, calendarEntry, account] = await Promise.all([
    h.SoccerGame.getDoc(p.soccerGameId),
    h.Team.getDoc(p.teamId),
    h.CalendarEntry.getDoc(p.calendarEntryId) as Promise<CalendarEntryGameScrimmage | null>,
    h.Account.getDoc(p.selfAccountId)
  ]);

  if (!soccerGame || !team || !calendarEntry) {
    throw new Error("Invalid ids supplied to notification__server__triggerForLiveGameModePhotosPlea");
  }

  if (soccerGame.calendarEntryId !== calendarEntry.id || soccerGame.teamId !== p.teamId) {
    throw new Error(
      "Relationships are not correct between fetched data in notification__server__triggerForLiveGameModePhotosPlea"
    );
  }

  let guardianAccountIds = Object.keys(team.accounts).filter(aid => !!team.accounts[aid]?.roles.guardian);
  if (soccerGame.squad) {
    guardianAccountIds = await fetchAccountIdsOnSquad({ team: team, squad: soccerGame.squad, squadSubset: "guardians" });
  }

  const triggerEventId = `photoPlea-${soccerGame.id}-${Date.now()}`;

  const accountPrivates = await fetchAccountPrivatesCached({ accountIds: guardianAccountIds });

  const communicationLocales = _.uniq(accountPrivates.map(ap => ap.communicationLocale));

  const lpNotificationsByLocale: Record<string, LowPriorityNotificationDetail> = communicationLocales.reduce((acc, locale) => {
    const title = this.translate({ defaultMessage: `Share Photos/Videos in Live Game Mode!`, serverLocale: locale });
    const body = this.translate(
      {
        defaultMessage: `{name} is requesting to see photos and videos from the game. Upload photos and videos in Live Game Mode now!`,
        serverLocale: locale
      },
      {
        name: account
          ? `${account.firstName} ${account.lastName[0]}.`
          : this.translate({ defaultMessage: `A parent on your team`, serverLocale: locale })
      }
    );

    const lp: LowPriorityNotificationDetail = {
      body: body,
      title: title,
      createdAtMS: now,
      expireAtMS: moment().add(30, "days").valueOf(),
      id: h.LowPriorityNotificationDetail.generateId(),
      routerPath: `liveGame/${calendarEntry.id}/timeline`,
      type: LowPriorityNotificationDetailType.photoPlea
    };

    acc[locale] = lp;
    return acc;
  }, {} as Record<string, LowPriorityNotificationDetail>);

  await Promise.all(
    Object.values(lpNotificationsByLocale).map(lp => {
      return h.LowPriorityNotificationDetail.add({ doc: lp });
    })
  );

  const notificationBundles: NotificationBundle[] = _.compact(
    guardianAccountIds
      .filter(
        aid =>
          aid !== p.selfAccountId &&
          team.accounts[aid]?.staffTitle !== Team__StaffTypes.assistantCoach &&
          team.accounts[aid]?.staffTitle !== Team__StaffTypes.headCoach
      )
      .map(accountId => ({ accountId, id: generatePushID() }))
      .map(a => {
        const communicationLocale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
        const lpnd = lpNotificationsByLocale[communicationLocale];
        if (lpnd) {
          return {
            type: NotificationType.lowPriorityNotification,
            id: a.id,
            accountId: a.accountId,
            triggerEventId,
            pushNotificationData: {
              body: lpnd.body,
              title: lpnd.title,
              id: a.id,
              pushNotificationSettingToRespect: PushNotificationSettingToRespect.statsAndGame,
              triggerEventId,
              type: NotificationType.lowPriorityNotification,
              routerPath: lpnd.routerPath,
              lowPriorityNotificationDetailType: lpnd.type,
              soccerGameId: soccerGame.id
            }
          };
        }
        return null;
      })
  );

  await processNotificationBundles({
    notificationBundles
  });
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForLiveGameModePhotosPlea.auth = (req: express.Request) => {
  validateToken(req);
};

// i18n certified - complete
