import {
  SoccerGame,
  Team,
  NotificationBundle,
  NotificationType,
  PushNotificationSettingToRespect,
  LowPriorityNotificationDetail,
  LowPriorityNotificationDetailType,
  CalendarEntry,
  CalendarEntryGameScrimmage,
  TeamFeatures,
  PollId,
  AccountId,
  CONVERSATION_TYPES,
  Account,
  AccountPrivate
} from "@ollie-sports/models";
import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { generatePushID } from "../../internal-utils/firebaseId";
import moment from "moment";
import { processNotificationBundles } from "./notification.plumbing";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { bulkFetchAccountIdsHavePremium, fetchAccountPrivatesCached, ObjectKeys } from "../../utils";
import { getListOfAccountIdsForOrgChatChannel, timeHasPassedSinceScheduledGameStart } from "../../compute";
import { Request } from "express";
import { conversation__server__accountIds } from "../conversation/conversation__accountIds";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import _ from "lodash";

export async function notification__server__triggerForPollReminder(this: ServerThisContext, p: { data: { pollId: PollId } }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const now = Date.now();

  const poll = await h.Poll.getDoc(p.data.pollId);

  if (!poll) {
    throw new Error("Could not find poll when trying to generate poll reminder message.");
  }

  if (now > poll.endDateTimeMS) {
    throw new Error("Poll has already ended, so we won't send reminders.");
  }

  const conversation = await h.Conversation.getDoc(poll.conversationId);

  if (!conversation) {
    throw new Error("Could not find conversation when trying to generate poll reminder message.");
  }

  let allAccountIdsInConvo: AccountId[] = [];
  if (conversation.conversationType === CONVERSATION_TYPES.accounts) {
    allAccountIdsInConvo = ObjectKeys(conversation.accounts);
  } else if (conversation.conversationType === CONVERSATION_TYPES.team) {
    allAccountIdsInConvo = await conversation__server__accountIds({
      conversationId: conversation.id,
      selfAccountId: poll.creatorAccountId
    });
  } else {
    let org = await h.Org.getDoc(conversation.orgId);
    if (!org) {
      throw new Error("Unable to find org");
    }
    let orgTeams = await h.Team.query({ where: [{ deletedAtMS: ["==", 0] }, { orgId: ["==", conversation.orgId] }] });
    allAccountIdsInConvo = getListOfAccountIdsForOrgChatChannel({
      orgFilters: conversation.orgFilters,
      org,
      orgTeams: orgTeams.docs
    });
  }

  const accountIdsWhoHaventVotedAndArentTheCreator = allAccountIdsInConvo.filter(
    accountId => accountId !== poll.creatorAccountId && !poll.votesByAccountId?.[accountId]
  );

  const [participantAccounts, participantAccountPrivates] = await Promise.all([
    (await h.Account.getDocs(accountIdsWhoHaventVotedAndArentTheCreator)).filter((a): a is Account => !!a),
    (
      await fetchAccountPrivatesCached({ accountIds: accountIdsWhoHaventVotedAndArentTheCreator })
    ).filter((a): a is AccountPrivate => !!a)
  ]);

  const accountsToNotify = participantAccounts.filter(account => {
    const accountPrivate = participantAccountPrivates.find(ap => ap.id === account.id);
    if (
      accountPrivate &&
      (!!accountPrivate.settings?.conversations?.[conversation.id]?.muted ||
        !accountPrivate.settings?.notifications.teamAndOrgChannels)
    ) {
      return false;
    }
    return true;
  });

  const triggerEventId = poll.id;

  const accountPrivates = _.compact(await h.AccountPrivate.getDocs(accountsToNotify.map(acc => acc.id)));

  const communicationLocales = _.uniq(accountPrivates.map(ap => ap.communicationLocale));

  const lpNotificationsByLocale: Record<string, LowPriorityNotificationDetail> = communicationLocales.reduce((acc, locale) => {
    const title = poll.question;
    const body = this.translate({
      defaultMessage: "A poll is closing in 1 hour. Get your vote(s) in before the poll closes.",
      serverLocale: locale
    });

    const lp: LowPriorityNotificationDetail = {
      body,
      title,
      createdAtMS: now,
      expireAtMS: moment().add(30, "days").valueOf(),
      id: h.LowPriorityNotificationDetail.generateId(),
      routerPath: `main/chat/conversation/${conversation.id}/MAGIC_FALSE/${poll.id}/MAGIC_FALSE`,
      type: LowPriorityNotificationDetailType.pollReminder
    };

    acc[locale] = lp;
    return acc;
  }, {} as Record<string, LowPriorityNotificationDetail>);

  await Promise.all(
    Object.values(lpNotificationsByLocale).map(lp => {
      return h.LowPriorityNotificationDetail.add({ doc: lp });
    })
  );

  const notificationBundles: NotificationBundle[] = _.compact(
    accountsToNotify
      .filter(account => account.id !== poll.creatorAccountId)
      .map(account => account.id)
      .map(accountId => ({ accountId, id: generatePushID() }))
      .map(a => {
        const communicationLocale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
        const lpnd = lpNotificationsByLocale[communicationLocale];
        if (lpnd) {
          return {
            type: NotificationType.lowPriorityNotification,
            id: a.id,
            accountId: a.accountId,
            triggerEventId,
            pushNotificationData: {
              body: lpnd.body,
              title: lpnd.title,
              id: a.id,
              pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
              triggerEventId,
              type: NotificationType.lowPriorityNotification,
              routerPath: lpnd.routerPath,
              lowPriorityNotificationDetailType: lpnd.type
            },
            realTimeNotification: {
              d: now,
              id: a.id,
              t: NotificationType.lowPriorityNotification,
              e: moment().add(30, "days").valueOf(),
              lpId: lpnd.id
            }
          };
        }
        return null;
      })
  );

  await processNotificationBundles({
    notificationBundles
  });
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForPollReminder.auth = async (req: Request) => {
  if (req.get("x-ph-signature")) {
    //TODO: Verify posthook signature...
  } else {
    await validateToken(req);
  }
  // i18n certified - complete
};
