import {
  Team,
  NotificationBundle,
  NotificationType,
  PushNotificationSettingToRespect,
  LowPriorityNotificationDetail,
  LowPriorityNotificationDetailType,
  CalendarEntryGameScrimmage,
  SGE_shotWithGoal
} from "@ollie-sports/models";
import { fetchAccountIdsOnSquad } from "../../internal-utils/team-utils";
import { generatePushID } from "../../internal-utils/firebaseId";
import moment from "moment";
import { processNotificationBundles } from "./notification.plumbing";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { bulkFetchAccountIdsHavePremium, fetchAccountPrivatesCached } from "../../utils";
import { timeHasPassedSinceScheduledGameStart } from "../../compute";
import _ from "lodash";
import { translate } from "@ollie-sports/i18n";
import {
  computeLastSoccerEventDescription,
  ConvenienceSoccerGamePlayer,
  createSoccerEventMemoryDB,
  SoccerEventMemoryDB,
  SoccerGoalEvent
} from "../../soccer-logic";

export async function notification__server__triggerForScoreUpdate(p: {
  soccerGameId: string;
  selfAccountId: string;
  team: Team;
  calendarEntry: CalendarEntryGameScrimmage;
  score: { ownTeam: number; opponentTeam: number };
  conveniencePlayers: ConvenienceSoccerGamePlayer[];
  goalEvent: SoccerGoalEvent;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const now = Date.now();

  const [soccerGame, team, calendarEntry] = await Promise.all([
    h.SoccerGame.getDoc(p.soccerGameId),
    h.Team.getDoc(p.team.id),
    h.CalendarEntry.getDoc(p.calendarEntry.id) as Promise<CalendarEntryGameScrimmage | null>
  ]);

  if (!soccerGame || !team || !calendarEntry) {
    throw new Error("Invalid ids supplied to notification__server__triggerForGameStart");
  }

  if (soccerGame.calendarEntryId !== calendarEntry.id || soccerGame.teamId !== p.team.id) {
    throw new Error("Relationships are not correct between fetched data in notification__server__triggerForGameStart");
  }

  if (p.selfAccountId !== soccerGame.recorderAccountId) {
    throw new Error("Cannot trigger score update notification if not the current stat recorder");
  }

  // Don't send notification if the game was scheduled in the past
  // Most likely watching film afterwards

  if (timeHasPassedSinceScheduledGameStart(calendarEntry, 4)) {
    return;
  }

  let accountIds = Object.keys(team.accounts);
  if (soccerGame.squad) {
    accountIds = await fetchAccountIdsOnSquad({ team: team, squad: soccerGame.squad, squadSubset: "all" });
  }

  const [{ accountIdsWithPremium, accountIdsWithoutPremium }, accountPrivates] = await Promise.all([
    bulkFetchAccountIdsHavePremium({
      accountIds,
      overrides: [],
      teamId: team.id
    }),
    fetchAccountPrivatesCached({ accountIds })
  ]);

  const triggerEventId = soccerGame.id + Date.now();

  const lpTitle = `${translate({
    defaultMessage: "Score Update",
    description: "alerting the user about an update in the score of the game",
    serverLocale: "en-us"
  })}: ${p.score.ownTeam}-${p.score.opponentTeam}`;
  const lpBody = `${p.team.name} ${translate.common("en-us").Vs} ${p.calendarEntry.opponentName}.`;

  // TODO: The score update should be it's own notification, not a LP Notification because we don't put it in the notification center
  const lp: LowPriorityNotificationDetail = {
    body: lpBody,
    title: lpTitle,
    createdAtMS: now,
    expireAtMS: moment().add(30, "days").valueOf(),
    id: h.LowPriorityNotificationDetail.generateId(),
    routerPath: `liveGame/${p.calendarEntry.id}/timeline`,
    type: LowPriorityNotificationDetailType.scoreUpdate
  };

  const notificationBundlesNotGated: NotificationBundle[] = accountIdsWithPremium
    .filter(aid => aid !== p.selfAccountId)
    .map(accountId => ({ accountId, id: generatePushID() }))
    .map(a => {
      const locale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
      const eventDescription = computeLastSoccerEventDescription({
        completeRoster: p.conveniencePlayers,
        eventIndex: 0,
        memoryDB: createSoccerEventMemoryDB([p.goalEvent]),
        locale
      }).replace(/\*/g, "");
      const title = `${translate({
        defaultMessage: "Score Update",
        description: "alerting the user about an update in the score of the game",
        serverLocale: locale
      })}: ${p.score.ownTeam}-${p.score.opponentTeam}`;
      const body = `${p.team.name} ${translate.common(locale).Vs} ${p.calendarEntry.opponentName}. ${eventDescription}`;
      return {
        type: NotificationType.lowPriorityNotification,
        id: a.id,
        accountId: a.accountId,
        triggerEventId,
        pushNotificationData: {
          body: body,
          title: title,
          id: a.id,
          pushNotificationSettingToRespect: PushNotificationSettingToRespect.statsAndGame,
          triggerEventId,
          type: NotificationType.lowPriorityNotification,
          routerPath: lp.routerPath,
          lowPriorityNotificationDetailType: lp.type,
          soccerGameId: p.soccerGameId
        }
      };
    });
  const notificationBundlesGated: NotificationBundle[] = accountIdsWithoutPremium
    .filter(aid => aid !== p.selfAccountId)
    .map(accountId => ({ accountId, id: generatePushID() }))
    .map(a => {
      const locale = accountPrivates.find(ap => ap.id === a.accountId)?.communicationLocale ?? "en-us";
      const title = `${translate({
        defaultMessage: "Score Update",
        description: "alerting the user about an update in the score of the game",
        serverLocale: locale
      })}: ${p.score.ownTeam}-${p.score.opponentTeam}`;
      const body = `${p.team.name} ${translate.common(locale).Vs} ${p.calendarEntry.opponentName}.`;
      return {
        type: NotificationType.lowPriorityNotification,
        id: a.id,
        accountId: a.accountId,
        triggerEventId,
        pushNotificationData: {
          body: body,
          title: title,
          id: a.id,
          pushNotificationSettingToRespect: PushNotificationSettingToRespect.statsAndGame,
          triggerEventId,
          type: NotificationType.lowPriorityNotification,
          routerPath: lp.routerPath,
          lowPriorityNotificationDetailType: lp.type,
          soccerGameId: p.soccerGameId
        }
      };
    });

  await processNotificationBundles({
    notificationBundles: [...notificationBundlesGated, ...notificationBundlesNotGated]
  });
  // SERVER_ONLY_TOGGLE
}

notification__server__triggerForScoreUpdate.auth = (req: express.Request) => {
  validateToken(req);
};

// i18n certified - complete
